@mixin how-to-play__item{
    width: 65%;
    flex: 0 0 auto;
    max-width: 404px;
    margin-right: $spacing-16;
    &:last-child {
        margin-right: 0;
    }
    &:nth-child(even) {
        margin-top: $spacing-40;
    }
    img[class*=fit-]{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
    }
    .fit-cover {
        object-fit: cover;
    }
    &-pt-size{
        display: block;
        position: relative;
        overflow: hidden;
        padding-top: calc(100% * 436/404);
        &:hover img{
            transform: scale(1.1);
        }
    }
    @media #{$media-screen-mobile} {
        width: 80%;
        margin-right: calc($spacing-16 /2);
        &:nth-child(even) {
            margin-top: $spacing-16;
        }
    }
   
}