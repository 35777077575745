// rooms modal
@mixin rooms-modal {
  .modal {
    &.show {
      display: flex !important;
      align-items: center !important;
    }

    .modal-content {
      .btn-close {
        width: 16px !important;
        position: absolute !important;
        right: 21px;
        top: 19px;
        z-index: $z-index-4;
        background: 0;
        padding: rem(6) rem(20);
        outline: none;
        border: none;

        i {
          width: 16px;
          height: 16px;
          cursor: pointer;
          color: $color-gray-200;
        }
      }
    }

    .modal-dialog {
      width: 100%;
      padding: 0 rem(16);
      max-width: 1032px;
    }

    .modal-body {
      height: 600px;
    }

    .modal-header {
      border: none;
    }

    .modal-body {
      display: flex;
      justify-content: space-between;
      padding: 0 !important;
    }

    .popup-left {
      width: 50%;
      overflow: hidden;
      position: relative;

      .slick-next, .slick-prev {
        top: 50%;
        right: 14px;
        z-index: $z-index-4;
        opacity: 1;
        padding: rem(18);
      }
      //.slick-next{
      //  background-image: url('#{$image-path}arowright.png');
      //  background-repeat: no-repeat;
      //}
      //
      //.slick-prev {
      //  left: 14px;
      //  right: unset;
      //  background-image: url('#{$image-path}arowleft.png');
      //  background-repeat: no-repeat;
      //}
      .slick-next, .slick-prev{
        display: none !important;
      }
      .slick-track {
        position: relative #{"/*!rtl:absolute*/"};
        right: 0 #{"/*!rtl:unset*/"};
        left: #{"/*!rtl:0*/"}unset;
      }
      .img-slide {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .popup-right {
      width: 50%;
      padding: rem(50) 0 rem(50) rem(25);
      text-align: left;
      background: $bg-body;
      height: auto;
      font-family:$font-NimbusSans;

      .title-top {
        color: var(--theme-color);
        font-family: $font-NimbusSans-Bold;
        font-size: $fs-35;
        text-align: initial;
      }

      .scroll-popupright::-webkit-scrollbar {
        width: 7px;
      }

      .scroll-popupright::-webkit-scrollbar-thumb {
        background: $color-gray-600;
        border-radius: 10px;
      }

      .scroll-popupright::-webkit-scrollbar-thumb:hover {
        background: $color-gray-600;
      }

      .scroll-popupright {
        overflow-y: scroll;
        height: 85%;
        padding: 0 rem(20) 0 0;
        .pading-popup {
          .text-poupright {
            padding-top: rem(6);

            h5 {
              padding: rem(20) 0;
            }
          }

          h5 {
            font-size: $fs-18;
            color: $color-white;
          }

          .upto-sqm {
            display: flex;
            font-size: $fs-18;
            align-items: center;
            color: $color-white;

            h4 {
              padding-left: rem(8);
              font-size: $fs-18;
            }
          }

          .occupancy {
            display: flex;
            color: $color-white;
            align-items: end;
            padding: rem(16) 0;

            h4 {
              padding-left: rem(8);
              font-size: $fs-18;
            }
          }

          .title-center {
            font-size: $fs-25;
            color: $color-white;
            font-family: $font-NimbusSans-Bold;
            padding: rem(10) 0;
          }
        }
      }

      .footer-popupright {
        background: var(--theme-color);
        border-radius: 12px;
        width: 152px;
        height: 37px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        margin-top: 20px;
        box-shadow: 1px 1px 1px 1px;

        a {
          font-size: $fs-12;
          font-family: $font-NimbusSans;
          color: $color-black;
          letter-spacing: 1.8px;
        }
      }
    }

    .draggable, .slick-track {
      height: 100% !important;
    }

    .arowpopup {
      z-index: 1;
      height: 0;
      //display: none;
      justify-content: space-between;
      cursor: pointer;
      .arow-left{
        position: absolute;
        top: 50%;
        left: #{"/*!rtl:unset*/"}24px;
        right: #{"/*!rtl:calc(50% - 32px - 24px)*/"} unset;
        transform: translateY(-50%);
      }
      .arow-right{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% - 32px - 24px) #{"/*!rtl:unset*/"};
        right: #{"/*!rtl:24px*/"} unset;
      }
    }

    @media #{$media-screen-mobile} {
      .modal-dialog {
        padding: 0;
        margin: 0 auto;
        max-width: 90%;
        .modal-content {
          .modal-body {
            flex-direction: column;
            height: inherit;
            .arowpopup {
              display: none;
            }

            .popup-right {
              padding: rem(20) rem(10) rem(20) rem(15);
              max-width: 100%;
              .scroll-popupright{
                max-height: 40vh;
              }
              h3 {
                font-size: $fs-28;
              }

              h5 {
                font-size: $fs-16;
              }
            }

            .popup-left {
              max-width: 100%;
              .slick-next, .slick-prev {
                display: none;
              }
            }
          }
        }
      }


      .show {
        display: block;

        .modal-body {
          display: block;
        }

        .modal-dialog {
          margin: 0 !important;
          height: 100%;
        }
      }

      .popup-right {
        width: 100%;
      }

      .popup-left {
        width: 100%;

        .img-slide img {
          width: 100%;
          object-fit: unset;
        }
      }
    }

  }
}

.rooms {
  @include rooms-modal;
  .rooms-list-product {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: auto;
    padding-bottom: rem(20);
    column-gap: $spacing-24;

    .item-card{
      margin-bottom: 27px;
    }
    .item-card__bgImg{
      z-index: 2;
      top: 0;
      height: inherit;
      .pa1{
        padding-top: 23.04%;
        background-color: transparent;
      }
    }
    .item-card__img{
      .pa1{
        padding-top: 73.529%;
      }
    }
    .item-card__content{
      margin-top: -$spacing-60;
      position: relative !important;
      left: 0;
      margin-left: $spacing-24;
      @media #{$media-screen-tablet} {
        margin-left: $spacing-12;
      }
      text-align: left;
      .title{
        @extend .d35-m28;
        color: var(--theme-txt-color);
      }
      .title,.capacity{
        margin-bottom: $spacing-8;
      }
      .capacity{
        font-family: $font-NimbusSans-Bold;
      }
      .capacity,.desc{
        @extend .d18-m14;
        color: $color-black-272727;;
      }
    }

  }
  @media #{$media-screen-mobile} {
    .rooms-list-product {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      margin: 0;
      .item-card{
        margin-bottom: $spacing-16;
      }
      .item-card__content{
        margin-left: $spacing-16;
        max-width: calc(100% - #{$spacing-16} * 2);
      }
    }
  }
}
