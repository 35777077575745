@mixin rewards-staycation-list{
  display: flex;
  flex-wrap: wrap;
  @media #{$media-md} {
    margin:0 calc(#{-$spacing-16} / 2);
    width: initial;
    height: initial;
  }
  @media #{$media-screen-mobile} {
    &.swiper-wrapper{
      flex-wrap: nowrap;
    }
  }
}