.venues {
 .item-card__img .pa1 {
    padding-top: 71.42%;
  }
  .venues__list-card{
    margin: 0 -$spacing-16;
    margin-bottom: $spacing-70;
    display: flex;
    flex-wrap: wrap
  }
  .item-card__container {
    padding: $spacing-45 $spacing-24 $spacing-24 $spacing-24;
  }
  .item-card{
    width: 50%;
    padding: 0 $spacing-16;
    .item-card__bgImg{
      top: $spacing-24;
      right: -$spacing-24;
    }
    .item-card__content{
      left: -$spacing-24;
      top: -$spacing-45;
      max-width: 100%;
      color: $color-black-272727;
      .title{
        @extend .d35-m28;
        margin-bottom: $spacing-8;
      }
      .title-des,.capacity,.des{
        @extend .d18-m14;
        margin-bottom: $spacing-8;
      }
      .title-des{
        font-family: $font-NimbusSans-Bold;
        text-transform: inherit;
      }
    }
    &:nth-child(even){
      margin-top: $spacing-40;
    }
  }

  @media #{$media-screen-mobile} {
    .venues__list-card{
      margin: 0 -$spacing-8;
      margin-bottom: $spacing-gutter;
    }
    .item-card{
      width: 100%;
      padding: 0;
      &:nth-child(even){
        margin-top: 0;
      }
    }
  }
}
