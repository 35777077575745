.paragraph {
  .heading-section {
    text-align: center;
  }
  .gallery__title, .rich-text p strong {
    color: var(--theme-color);
  }
  .rich-text {
    table ul li a {
      text-decoration: none;
      color: var(--theme-color);
    }
  }
}
