.cta {
  display: block;
  text-decoration: none;
  font-size: $fs-12;
  font-family: $font-Futura-Medium;
  letter-spacing: 1.2px;
  font-weight: 500;
  padding: rem(10) rem(24);
  line-height: 1;
  border-radius: rem(16);
  color: $color-black;
  width: fit-content;
  @media #{$media-md} {
    display: inline-block;
  }
  &.cta-pink {
    background: $bg-color-efc3b5;
  }

  &.cta-pink-light {
    background: transparent;
    color: $bg-color-efc3b5;
    border: 1px solid $bg-color-efc3b5;
  }
}
