@use "sass:math";

.gallery {
  margin-bottom: $spacing-gutter;
  @media #{$media-md} {
    margin-bottom: $spacing-80;
  }

  &__title, &__title h2 {
    margin-bottom: $spacing-gutter;
    color: var(--theme-color);
    @extend .d35-m28;
  }

  &__image-box {
    max-width: 400px;
    margin-left: rem(8);
    margin-right: rem(8);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(even) {
      margin-top: rem(40);
    }
  }

  &__image {
    width: auto;
    padding-bottom: 105%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__content{
    position: relative;
    cursor: unset;
  }
  @media #{$media-screen-mobile} {
    &__image-box {
      max-width: 300px;
      margin-left: rem(4);
      margin-right: rem(4);

      &:nth-child(even) {
        margin-top: rem(16);
      }
    }
  }
}
.gallery__content{
  @include swiper-navigation;
}
.msocial-merlion-package {
  @include mb-section;

  .heading-section {
    @include heading-section--stb-campaign-site;
  }
}
