.featured-offers {
  .heading-section {
    // padding: 0 $spacing-16;
    margin-bottom: calc(#{$spacing-16} * 2);
    text-align: center;
    margin-top: rem(140);

    @media #{$media-md} {
      padding-bottom: $spacing-16;
      // margin-right: $spacing-40;
      margin-bottom: $spacing-63;
      text-align: right;
    }
  }

  @include rooms-modal;
}

