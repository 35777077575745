.logo-region{
  $this:&;
  padding: $spacing-gutter 0;
  background-size: contain;
  background-repeat: repeat;
  &__position{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after, 
    &:before {
      content: '';
      border-top: 1px solid $color-black-272727;
      display: block;
      width: 100%;
    }
    &:after {
      margin-left: $spacing-24;
    }
    &:before {
      margin-right: $spacing-24;
    }
    img{
      width: 172px;
    }
    &--link {
      img{
        filter: invert(0.5);
        transition: 0.3s;
      }
      a:hover img{
        filter: invert(0);
      }
    }
  }
  &-list{
    display: flex;
    justify-content: center;
    padding:  calc($spacing-24 + 2px) 0;
    overflow: hidden;
  }
  &-item{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-right: 1px solid $color-black-272727;
    padding: calc($spacing-24 + $spacing-12) $spacing-24;
    img{
      max-width: 100%;
      width: 100%;
      height: auto;
    }
    &:first-child{
      padding-left: 0;
      #{$this}-item__title{
        padding-left: 0;
        padding-right: $spacing-24;
      }
    }
    &:last-child{
      border-right: 0;
      padding-right: 0;
      #{$this}-item__title{
        padding-left: $spacing-24;
        padding-right: 0;
      }
    }
    #{$this}-item__title{
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      bottom: 0;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.4);
      padding: 0 $spacing-12;
    }
    #{$this}-item__logo{
      display: flex;
      align-items: center;
      #{$this}-item__logo-link{
        &:not(:last-child){
          padding-right: $spacing-gutter;
        }
        img{
          // filter: grayscale(100%);
          filter: invert(0.5);
          transition: 0.3s;
        }
        &:hover img{
          filter: invert(0);
        }
      }
    }
  }

  @media #{$media-screen-mobile} {
    &-list{
      flex-wrap: wrap;
    }
    &-item{
      border-right: 0;
      padding: $spacing-16 $spacing-12 $spacing-24 $spacing-12;
      #{$this}-item__logo{
        #{$this}-item__logo-link{
          &:not(:last-child){
            padding-right: $spacing-24;
          }
        }
      }
      &:first-child{
        #{$this}-item__title{
          padding-right: $spacing-12;
        }
      }
      &:last-child{
        #{$this}-item__title{
          padding-left: $spacing-12;
        }
      }
    }
  }
}