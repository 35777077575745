// fonts variables
$font-objectSans-regular: 'ObjectSans Regular', sans-serif;
$font-objectSans-bold: 'ObjectSans Bold', sans-serif;
$font-objectSans-black: 'ObjectSans Black', sans-serif;
$font-objectSans-blackSlanted: 'ObjectSans BlackSlanted', sans-serif;
$font-objectSans-heavy: 'ObjectSans Heavy', sans-serif;
$font-objectSans-light: 'ObjectSans Light', sans-serif;
$font-objectSans-lightSlanted: 'ObjectSans LightSlanted', sans-serif;
$font-objectSans-Medium: 'ObjectSans Medium', sans-serif;
$font-objectSans-mediumSlanted: 'ObjectSans MediumSlanted', sans-serif;
$font-objectSans-slanted: 'ObjectSans Slanted', sans-serif;
$font-objectSans-thin: 'ObjectSans Thin', sans-serif;
$font-objectSans-thinSlanted: 'ObjectSans ThinSlanted', sans-serif;
$font-objectSans-boldSlanted: 'ObjectSans BoldSlanted', sans-serif;

$font-family-base: $font-objectSans-regular;

$font-futuraLT: 'FuturaLT', sans-serif;
$font-futuraLT-bold: 'FuturaLT-Bold', sans-serif;
$font-futuraLT-book: 'FuturaLT-Book', sans-serif;
$font-futuraLT-heavy: 'FuturaLT-Heavy', sans-serif;
$font-futuraLT-lightOblique: 'FuturaLT-LightOblique', sans-serif;
$font-futuraLT-oblique: 'FuturaLT-Oblique', sans-serif;
$font-starlitDrive: 'StarlitDrive', sans-serif;
$font-FuturaLT-Regular: 'FuturaLT-Regular', sans-serif;
$font-Futura-Medium: 'Futura-Medium', sans-serif;
$font-HelveticaNeue-Light: 'HelveticaNeue-Light', sans-serif;
$font-HelveticaNeue-Regular: 'HelveticaNeue-Regular', sans-serif;
$font-HelveticaNeue-Bold: 'HelveticaNeue-Bold', sans-serif;
$font-HelveticaNeueLTStd-65Medium: 'HelveticaNeueLTStd-65Medium', sans-serif;
$font-HelveticaNeueLTStd-55Roman: 'HelveticaNeueLTStd-55Roman', sans-serif;
$font-NimbusSans-Bold: 'NimbusSanL-Bol', sans-serif;
$font-NimbusSans: 'NimbusSanL-Reg', sans-serif;
$font-DS-Digi: 'DS-Digi', sans-serif;
$font-GoldmanSans-Rg: 'Goldman-Sans-Rg', sans-serif;
$font-GoldmanSans-Bd: 'Goldman-Sans-Bd', sans-serif;
$font-Goldman-Rg: 'Goldman-Rg', sans-serif;
$font-Goldman-Bd: 'Goldman-Bd', sans-serif;

// fonts-size-base
$font-base-1: 18px;
$font-base-2: 16px;
$line-height-base: 1.15;
$headings-line-height: 1.3;
$fs-40:40px;
$font-title: 36px;

$fs-12:12px;
$fs-14:14px;
$fs-16:16px;
$fs-18:18px;
$fs-20:20px;
$fs-24:24px;
$fs-25:25px;
$fs-28:28px;
$fs-35:35px;
$fs-44:44px;
$fs-60:60px;


@mixin fontsizeMedia($desktop-fs,$mobile-fs){
  font-size: $desktop-fs;
  @media #{$media-screen-mobile} {
    font-size: $mobile-fs;
  }
}

@mixin d60-m40 {
  font-size: 60px;
  @media #{$media-screen-tablet} {
    font-size: 55px;
  }
  @media #{$media-screen-mobile} {
    font-size: 40px;
  }
}

@mixin d35-m28 {
  font-size: 35px;
  @media #{$media-screen-tablet} {
    font-size: 25px;
  }
  @media #{$media-screen-mobile} {
    font-size: 28px;
  }
}
@mixin d35-m20 {
  font-size: 35px;
  @media #{$media-screen-tablet} {
    font-size: 25px;
  }
  @media #{$media-screen-mobile} {
    font-size: 20px;
  }
}

@mixin d30-m24 {
  font-size: 30px;
  @media #{$media-screen-tablet} {
    font-size: 27px;
  }
  @media #{$media-screen-mobile} {
    font-size: 24px;
  }
}

@mixin d30-m20 {
  font-size: 30px;
  @media #{$media-screen-tablet} {
    font-size: 27px;
  }
  @media #{$media-screen-mobile} {
    font-size: 20px;
  }
}
@mixin d25-m20 {
  font-size: 25px;

  @media #{$media-screen-mobile} {
    font-size: 20px;
  }
}

@mixin d24-m18 {
  font-size: 24px;
  @media #{$media-screen-tablet} {
    font-size: 22px;
  }
  @media #{$media-screen-mobile} {
    font-size: 18px;
  }
}

@mixin d20-m16 {
  font-size: 20px;

  @media #{$media-screen-mobile} {
    font-size: 16px;
  }
}

@mixin d18-m16 {
  font-size: 18px;

  @media #{$media-screen-mobile} {
    font-size: 16px;
  }
}

@mixin d18-m14 {
  font-size: 18px;

  @media #{$media-screen-mobile} {
    font-size: 14px;
  }
}

@mixin d16-m14 {
  font-size: 16px;

  @media #{$media-screen-mobile} {
    font-size: 14px;
  }
}