.dining-related-offers-list {

  .section-item-dining__head {
    font-family: $font-NimbusSans;
    color: $bg-color-white;
    margin-bottom: $spacing-gutter;
    @extend .d18-m16;
    text-align: center;

    &-title{
      font-family: $font-NimbusSans-Bold;
      line-height: normal;
      text-transform: uppercase;
      @extend .d40-m28;
      span{
        color: var(--theme-color);
      }
    }
  }

  .item-card__content p{
    text-transform: inherit;
    font-family: $font-NimbusSans;
  }

  .item-card__content{
    color:$color-black-272727;
    .title-country-dining{
      @extend .d25-m16;
      font-family: $font-NimbusSans-Bold;
      text-transform: uppercase;
    }
    .des-country-dining,.author{
      @extend .d18-m14;
      margin-top: calc(#{$spacing-16} / 2);
      text-transform: inherit;
    }
  }
  .item-card {
    &:nth-child(even) {
      margin-top: $spacing-24;
    }
  }
  .related-offers{
    @include list-related-offers;
  }
}
