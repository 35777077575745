.offers {
  h1,h2,h3{
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  .offers__box{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: -$spacing-8;
  }
  .item-card{
    width: calc(100% / 3);
    padding: $spacing-8;
    &:not(.no-has-image){
      .item-card__content-bg{
        .item-card__bgImg{
          height: inherit;
          top: 0;
          left: 0;
          z-index: 2;
          .pa1{
            padding-top: 23.07%;
            background-color: rgba(0,0,0,0);
          }
        }
        .item-card__content{
          margin-top: -92px;
          position: relative !important;
          max-width: calc(100% - #{$spacing-24} * 2);
          margin-left: $spacing-24;
          left: 0;
        }
      }
    }
  }
  .item-card__content-bg{
    .item-card__img{
      .pa1{
        padding-top: 136.138%;
      }
    }
    .item-card__content{
      padding: $spacing-gutter $spacing-16;
      max-width: 100%;
      color: $color-black-272727;
      background-color: $bg-color-white;
      .title{
        @extend .d35-m28;
        font-family: $font-NimbusSans-Bold;
      }
      .title,.title-des{
        margin-bottom: $spacing-8;
      }
      .title-des,.desc{
        font-size: $fs-18;
      }
      .title-des{
        font-family: $font-NimbusSans-Bold;

      }
      .desc{
        font-family: $font-NimbusSans;
        position: relative;
        display: flex;
        align-items: center;
        padding-right: calc(#{$spacing-48} + #{$spacing-16});
        &:before {
          position: absolute;
          content: $icon-arrow-right-button;
          font-family: 'icomoon' !important;
          color: var(--theme-color);
          transform: #{"/*!rtl:rotate(180deg)*/"} inherit;
          @extend .d48-m32;
          right: 0;
        }
      }
      .item-card__bottom{
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    }
  }

  .no-has-image{
    .item-card__content-bg{
      .item-card__content{
        background: var(--theme-color);
        color: var(--theme-txt-color);
        .title-des{
          color: var(--theme-txt-color);
        }
        .desc{
          &:before {
            color: var(--theme-txt-color);
          }
        }

      }
    }
  }
  .item-card:not(.no-has-image){
    .item-card__content{
      .title-des{
        @extend .d18-m16;
      }
      .desc{
        @extend .d18-m14;
      }
    }
  }
  @media #{$media-screen-tablet} {
    .item-card{
      width: 50%;
    }
  }
  @media #{$media-screen-mobile} {
    .item-card{
      width: 100%;
      &{
        .item-card__content-bg{
          .item-card__content{
            padding: $spacing-16;
            .desc{
              padding-right: calc(#{$spacing-gutter} + #{$spacing-16});
            }
          }
        }
      }
      &:not(.no-has-image){
        .item-card__content-bg{
          .item-card__content{
            margin-top: -80px;
            margin-left: $spacing-16;
            max-width: calc(100% - #{$spacing-16} * 2);
          }
        }
      }
      &.no-has-image{
        .item-card__content-bg{
          .item-card__content{
            .title{
              font-size: $fs-35;
            }
          }
        }
      }

    }
  }
}


