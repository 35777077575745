@mixin mb-section  {
  margin-bottom: $spacing-section-mb;

  @media #{$media-md} {
    margin-bottom: $spacing-section;
  }
}
.mb-section {
  margin-bottom: $spacing-section-mb;

  @media #{$media-md} {
    margin-bottom: $spacing-section;
  }
}

.mb-header {
  margin-bottom: $mb-header-mb;

  @media #{$media-md} {
    margin-bottom: $mb-header;
  }
}


.mb-component {
  margin-bottom: $mb-header-mb;

  @media #{$media-md} {
    margin-bottom: $mb-header;
  }
}

.mb-component-lg {
  margin-bottom: 2.5rem;

  @media #{$media-md} {
    margin-bottom: 4rem;
  }
}

.pt-component{
  padding-top: 2.5rem;

  @media #{$media-md} {
    padding-top: 4rem;
  }
}

.pb-component{
  padding-bottom: 2.5rem;

  @media #{$media-md} {
    padding-bottom: 4rem;
  }
}