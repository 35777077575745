$bg-body: #272727;
$bg-color-orange-yellow: #ffb81d;
$bg-color-singapore-yellow: #ecc780;
$bg-color-bluegrey: #809fac;
$bg-color-soft-pink: #faa6c8;
$bg-color-very-light-purple: #edddf8;
$bg-color-sea: #45998a;
$bg-color-6ebac4: #6ebac4;
$bg-bright-lavender: $color-primary;
$color-orange-yellow-f1c675: #f1c675;
$color-black-272727: #272727;
$bg-color-efc3b5:#efc3b5;
$bg-color-white:#fff;
$color-black-000:#000;