.rewards-staycation{
    &-list{
        @include rewards-staycation-list;
    }
    &-item{
        @include rewards-staycation-item;
    }  
    @media #{$media-screen-mobile} {
        .js-swiper-mobile{
            margin: 0 calc(#{-$spacing-16 + 1});
            padding-left: calc(#{$spacing-16 - 1});
        }  
    }
}