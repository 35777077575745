.m-social {
  position: relative;
  transition: 0.5s;
  right: 0;
  //max-width: 1280px;
  //margin: 0 auto;
  // overflow: hidden;
  background-position: center;
  background-repeat: repeat;
  background-size: contain;

  &.show {
    right : $wSideBar;
    display: block;
    align-items: unset;
    transition: 0.6s;
    .sidebar {
      right: 0;
      transition: 0.5s;
    }
  }

  @media #{$media-screen-mobile} {
    &.show {
      right: 0 !important;
    }
  }
}
