@use 'sass:math';
.map {
      position: relative;

      &__container {
        width: 100%;
        padding-bottom: percentage(math.div(620, 1232));
      }

      &__detail {
        position: absolute;
        background: $color-white;
        padding: rem(24);
        bottom: 24px;
        left: 24px;
      }

      &__inf-title {
        @extend .d35-m28;
        font-family: $font-NimbusSans-Bold;
        color: var(--theme-color);
        padding-bottom: rem(24);
        h2{
          @media #{$media-screen-tablet} {
            font-size: inherit;
          }
        }
      }

      &__inf-detail {
        @extend .d18-m14;
        margin-bottom: rem(16);
        padding-left: rem(37);
        position: relative;
        font-family: $font-NimbusSans;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      .icon-location {
        font-size: 16px;
      }

      .icon-walk {
        font-size: 19px;
      }

      .icon-drive {
        font-size: 12px;
      }
      @media #{$media-screen-mobile} {
        &__detail {
          transform: translateY(-40px);
          margin-left: $spacing-16;
          width: calc(100% - #{$spacing-16}*2);
          position: unset;
        }
      }

    }
