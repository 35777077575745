//Font Size
$fontSizeBase: 16px;

//Spacing
$spacing-section: 120px;
$spacing-section-mb: 60px;
$mb-header: 60px;
$mb-header-mb: 32px;
$padding-component: 60px;
$padding-component-mb: 32px;
