@font-face {
  font-family: 'icomoon';
  src:  url($icon-path + 'icons/icomoon.eot?af1c00');
  src:  url($icon-path + 'icons/icomoon.eot?af1c00#iefix') format('embedded-opentype'),
    url($icon-path + 'icons/icomoon.ttf?af1c00') format('truetype'),
    url($icon-path + 'icons/icomoon.woff?af1c00') format('woff'),
    url($icon-path + 'icons/icomoon.svg?af1c00#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  @extend %icon;
}

$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-up: "\e902";
$icon-arrow-right: "\e903";
$icon-close: "\e904";
$icon-facebook: "\e905";
$icon-linkedin: "\e906";
$icon-minus: "\e907";
$icon-plus: "\e908";
$icon-search: "\e909";
$icon-swirl: "\e90a";
$icon-youtube: "\e90b";
$icon-twitter: "\ea96";
$icon-whatsapp: "\ea93";
$icon-maintenance: "\e90c";
$icon-bell: "\e90f";
$icon-instagram: "\ea92";
$icon-arrow-right-button: "\e90d";
$icon-shopping: "\e90e";
$icon-calendar: "\e910";
$icon-drive: "\e911";
$icon-location: "\e912";
$icon-walk: "\e913";
$icon-login: "\e914";
$icon-email: "\e915";
$icon-phone: "\e916";
$icon-up-arrow: "\e917";
$icon-global-arrow-left: "\e919";
$icon-global-arrow-right: "\e918";
$icon-mute: "\e91a";
$icon-unmute: "\e91b";


.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-swirl {
  &:before {
    content: $icon-swirl;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-maintenance {
  &:before {
    content: $icon-maintenance;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-arrow-right-button {
  &:before {
    content: $icon-arrow-right-button;
  }
}
.icon-shopping {
  &:before {
    content: $icon-shopping;
    color: #fff;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-drive {
  &:before {
    content: $icon-drive;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-walk {
  &:before {
    content: $icon-walk;
  }
}
.icon-login {
  &:before {
    content: $icon-login;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
    color: #fff;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
    color: #fff;
  }
}
.icon-up-arrow {
  &:before {
    content: $icon-up-arrow;
    color: var(--theme-txt-color);
  }
}
.icon-global-arrow-left {
  &:before {
    content: $icon-global-arrow-left;
    @extend .icon-box-radius;  
  }
}
.icon-global-arrow-right {
  &:before {
    content: $icon-global-arrow-right;    
    @extend .icon-box-radius;    
  }
}

.icon-box-radius{
  color: var(--theme-color);
  box-shadow: 0 0 8px 3px rgb(0 0 0 / 23%);
  border-radius: 50%;
}

.icon-mute {
  &:before {
    content: $icon-mute;     
    color: #fff;
  }
}
.icon-unmute {
  &:before {
    content: $icon-unmute;     
    color: #fff;
  }
}