@use 'sass:math';
.home-hero-banner {
  position: relative;
  &__video , &__image {
    height: 100vh;
    overflow: hidden;
    min-height: 600px;
    position: relative;
  }

  video {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-bottom {
    display: none;
    position: absolute;
    bottom: 0;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 18.6%;

    img {
      object-fit: unset;
    }
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  &__text-box {
    position: absolute;
  }

  &__title {
    font-size: $fs-24;
    color: $color-primary;
    padding-bottom: rem(8);
    font-family: $font-futuraLT-bold;
    letter-spacing: 1.92px;
    text-align: center;
  }

  &__desc {
    font-size: $fs-20;
    color: var(--theme-txt-second-color);
    text-align: center;
  }

  &--top {
    .home-hero-banner__text-box {
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--middle {
    .home-hero-banner__text-box {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--bottom {
    .home-hero-banner__text-box {
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__icon-volumn{
    position: absolute;
    opacity: 0;
    left: $spacing-24;
    background-color: transparent;
    cursor: pointer;
    border:0;
    i{
      font-size: 28px;
    }
  }
  @media #{$media-screen-mobile} {
    &__icon-volumn{
      i{
        font-size: 24px;
      }
    }
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }


  &.img-path {
    .home-hero-banner__image-bottom {
      display: block;
    }
  }

}
