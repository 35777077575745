.item-prizes{
    width: 100%;
    text-align: center;
    &__link{
        text-decoration: none;
    }
    &__img{
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: 0.3s;
        }
        &:hover img{
            transform: scale(1.05);
        }
    }
    &__content{
        position: relative;
        color: $color-white;
    }
   
    &__text{
        padding: calc($spacing-24 * 2) $spacing-24;
        min-height: 540px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
            @extend .d35-m20;
            text-transform: uppercase;
            margin-bottom: $spacing-8;
        }
        .desc{
            @extend .d20-m16;
            line-height: 28px;
            font-family: $font-NimbusSans;
        }
    }
    &__bgImg{
        position: absolute;
        top: 0;
        width: 100%;
        &--bottom{
            transform: rotate(180deg);
            bottom: 0;
        }
        .fit-cover{
            padding-top: calc(100% * (94/420));
            position: relative;
            overflow: hidden;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .bg-img-large {
            display: none;
        }
    }
    @media #{$media-md} {
        &__link{
            display: flex;
            position: relative;
            overflow: hidden;
        }
        &__img{
            width: 66.55%;
        }
        &__content{
            width: 34.45%;
        }
    }
    
    @media #{$media-screen-tablet-desktop} {
        &__content{
            width: 50%;
        }
        &__img{
            width: 50%;
        }
        
    }
    @media #{$media-not-desktop} {
        &__text{
            min-height: 350px;
        }
    }
   
    @media #{$media-screen-mobile} {
        &__content{
            width: 100%;
        }
        &__text{
            padding: calc($spacing-16 * 3) ;
            .desc{
                line-height: 1.25;
            }
            .title{
                margin-bottom: $spacing-16;
            }
        }
        &__img{
            padding-top: calc(100% * 229/344);
            position: relative;
        }
    }
    @media #{$media-screen-mobile-normal} {
        &__text{
            min-height: 300px;
        }
    }
   
}  