.dining-contactus {
  .dine-ws {
    .heading-section {
      max-width: 900px;
      margin:0 auto;
      text-align: center;
      margin-bottom: $spacing-60;

    }

    .section-item-dining {
      & .heading-section {
        color: var(--theme-txt-second-color);
        margin-bottom: $spacing-gutter;
        @extend .d20-m16;
      }

      &__head {
        font-family: $font-NimbusSans;
        color: var(--theme-txt-second-color);
        margin-bottom: $spacing-gutter;
        @extend .d18-m16;
        &-des{
          margin-bottom: $spacing-16;
          margin-top: calc(#{$spacing-16} / 2);
        }
        a {
          text-decoration: none;
          color: var(--theme-txt-second-color);
        }
        &-title{
          font-family: $font-NimbusSans-Bold;
          line-height: normal;
          text-transform: uppercase;
          @extend .d40-m28;
          span{
            color: var(--theme-color);;
          }
        }
        &-phone{
          margin-bottom: calc(#{$spacing-16} / 2);
        }
        & i{
          margin-right: calc(#{$spacing-16} / 2);
          font-size: $fs-24;
          transform: translateY(2px) #{"/*!rtl:rotateY(180deg) translateY(2px)*/"};
          display: inline-block;
        }
      }
      .dine-ws__title{
        .section-item-dining__head {
          text-align: left;
        }
      }
    }

    .item-card {

      &:nth-child(even) {
        margin-top:  $spacing-78;

        @media #{$media-screen-mobile} {
          margin-top: $spacing-16;
        }
      }
    }

    .section__card .item-card__content-bg .item-card__content p{
      text-transform: inherit;
      font-family: $font-NimbusSans;
    }

    .item-card__bgImg,.item-card__content{
      display: none
    }

    .item-card__content{
      color:$color-black-272727;
      .title-country-dining{
        @extend .d25-m16;
        font-family: $font-NimbusSans-Bold;
        text-transform: uppercase;
      }
      .des-country-dining,.author{
        @extend .d18-m14;
        margin-top: calc(#{$spacing-16} / 2);
        text-transform: inherit;
      }
    }


    @media #{$media-lg} {

      opacity: 1;
      transition-duration: unset;
      & .card__title{
        width: 45%;
        min-width: 45%;
        padding-right: 2rem;
      }

      .list-card .item-card {
        width: 65.7%;
        padding: 0;
        & .item-card__content-bg {
          .item-card__img .pa1{
            padding-top: 107.75%
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }

    }

    @media #{$media-not-desktop} {


      & {
        flex-direction: column;
        .card__title{
          text-align: left;
          width: 100%;
        }
        .heading-section{
          margin-bottom: $spacing-gutter;
          .heading-section__title{
            color: $color-secondary;
            margin-bottom:  calc(#{$spacing-16} /4);
          }
          .heading-section__btnBook {
            margin-top: $spacing-24;
            a{
              background-color: $color-secondary;
            }
          }
        }
        .section-item-dining {
          & .section-item-dining__head {
            padding-right: $spacing-16;
            padding-left: $spacing-16;
            margin-bottom:  $spacing-16;
            &-des{
              margin-bottom: $spacing-16;
              font-family: $font-NimbusSans;
              margin-top: calc(#{$spacing-16} / 2);
            }
            &-title{
              margin-bottom: 0;
              text-align: center;
              span{
                color: $color-secondary;
              }
            }
          }

          .card__title {
            text-align: left;
          }
          & .section-item-dining__head {
            &-title{
              text-align: left;
            }
          }

        }
        .list-card {
          .item-card {
            & .item-card__content-bg {
              margin-left:0;
              margin-right: 0;
              .item-card__img .pa1{
                padding-top: 106.67%;
              }

            }
          }
        }


      }


    }

  }
}
