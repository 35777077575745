@use "sass:math";

.booking {
  z-index: 3;
  width: 100%;
  position: relative;

  form {
    position: relative;
  }

  input {
    &::placeholder {
      color: $color-calendar-disable;
    }
  }

  &__content {
    position: absolute;
    padding: rem(36) rem(40) rem(30) rem(60);
    width: 100%;
    background: $color-white;
    border-radius: rem(76);
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    font-family: $font-HelveticaNeue-Regular;
    @media #{$media-screen-mobile} {
      border-radius: rem(40);
    }
  }

  &__left {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
  }

  &__right {
    flex: 0 0 74px;
    padding-left: rem(24);
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: $fs-16;
    font-family: $font-futuraLT-bold;
    color: $color-black-272727;
    padding-bottom: rem(15);
    text-transform: uppercase;
  }

  &__column {
    padding: 0 rem(12);
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.error {
      .booking__destination-bottom,
      .booking__travel-period-bottom,
      .booking__people-adult div:first-child:before,
      .booking__people-child div:first-child:before,
      .booking__promo-code-bottom {
        border-bottom: 1px solid $color-error;
      }
    }

  }

  &__destination {
    flex: 1 1 percentage(math.div(250, 1042));
  }

  &__travel-period {
    flex: 0 0 percentage(math.div(345, 1042));
  }

  &__people {
    flex: 0 0 percentage(math.div(210, 1042));
  }

  &__promo-code {
    flex: 1 1 percentage(math.div(167, 1042));
  }

  &__button {
    display: inline-block;
    transform: #{"/*!rtl:rotate(180deg)*/"} inherit;

    button {
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
    }

    .icon-arrow-right-button {
      &:disabled{
        opacity: 0.2;
        cursor: initial;
      }
      &:before {
        font-size: 48px;
        color:$color-black-000;
        @media #{$media-screen-mobile} {
          font-size: rem(32);
        }
      }
    }
  }

  &__destination-bottom {
    padding-bottom: 11px;
    border-bottom: 1px solid $color-black-272727;
    position: relative;

    .icon-arrow-down {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.5s linear;

      &.active {
        display: inline;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__travel-period-bottom {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-black-272727;
    padding-bottom: 11px;
  }

  &__people-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid transparent;
  }

  &__people-adult, &__people-child {
    display: flex;
    color: $color-calendar-disable;
    input {
      border: none;
      outline: none;
      width: 30px;
      padding: 0;
      font-size: $fs-18;
      color: $color-black-272727;
      text-align: center;
      font-family: $font-HelveticaNeue-Regular;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color-black-272727;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $color-black-272727;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $color-black-272727;
      }

    }

    div {
      &:nth-child(2) {
        padding-left: 3px;
      }

      &:first-child {
        position: relative;
        padding-bottom: 3px;

        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background: $color-black-272727;
          bottom: -9px;
        }
      }
    }

  }

  &__error-container {
    width: 100%;

    label {
      padding-top: rem(8);
      display: block;
      color: $color-error;
      font-size: $fs-18;
    }
  }

  &__promo-code-bottom {
    border-bottom: 1px solid $color-black-272727;
    padding-bottom: 11px;

    input {
      padding: 0;
      border: none;
      outline: none;
      font-size: $fs-18;
    }
  }

  &__button--mobile {
    display: none;
  }

  .custom-select2 {
    $color-scrollbar-thumb:   rgba(84, 42, 3, 0.2);

    display: inline-flex;
    position: relative;
    width: 100%;
    z-index: 2;

    // custom scrollbar
    .select2-results {
      .select2-results__options {
        max-height: 320px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scrollbar-color: linear-gradient(90deg, transparent, transparent);

        &::-webkit-scrollbar {
          position: fixed;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: linear-gradient(90deg, $color-scrollbar-thumb 3px, transparent 20%,transparent 50%);
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 10px;
        }

        @-moz-document url-prefix() {
          scrollbar-width: thin !important;
          scrollbar-color: $color-scrollbar-thumb transparent !important;
          margin-right: 10px;
        }

        .select2-results__option[role="option"] {
          padding: 1rem;
        }
      }
    }

    .select2-selection--multiple, .select2-selection--single {
      background-color: $color-transparent;
      border: none;
      border-radius: 0;
      cursor: pointer;
      min-height: auto;
      //height: 100%;
      height: unset;

      &:focus-visible {
        outline: none;
      }

      .select2-selection__rendered {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 0;
        line-height: unset;
        font-size: $fs-18;

        .select2-selection__choice {
          display: none;
        }
      }

      .select2-selection__placeholder {
        color: $color-calendar-disable;
      }
    }

    .select2-selection__arrow {
      display: none;
    }

    .select2.select2-container {
      width: 100% !important;
    }

    .select2-dropdown {
      width: 310px !important;
      border: none;
    }

    .select2-container--above ~ div {
      .select2-container {
        margin-top: -10px;
      }
    }

    .select2-container--below ~ div {
      .select2-container {
        margin-top: 10px;

      }
    }

    .dropdown-box {

      .select2-results__options .select2-results__option[role=option] {
        padding: rem(8) rem(16);
      }
    }

    .select2-results__option {
      font-size: $fs-18;
      font-family: $font-futuraLT;
      color: $color-black-272727;
    }

    .select2-selection__rendered {
      width: calc(100% - 10px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block !important;
    }

    .select2-container--default .select2-results__option--highlighted[aria-selected] {
      background-color: $color-primary;
    }
  }

  .date-range-picker {
    width: 100%;
    position: relative;

    &__text-show {
      display: flex;
      position: absolute;
    }

    .icon-calendar {
      position: absolute;
      right: 0;
      top: 50%;
      left: 95%;
      transform: translateY(-50%);
    }

    &__from, &__to {
      font-size: $fs-18;
      font-family: $font-futuraLT;
      color: $color-calendar-disable;
      //padding-right: rem(8);
      display: flex;
      @media #{$media-screen-mobile} {
        font-size: $fs-12;
      }
    }

    &__start, &__end {
      padding-left: rem(8);
      color: $color-black-272727;
      font-family: $font-futuraLT;
    }

    &__to {
      padding-left: rem(15);
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      opacity: 0;
      padding: 0;
      font-size: $fs-18;
      font-family: $font-HelveticaNeue-Regular;
    }

    &__content {
      position: relative;
    }

    .daterangepicker {
      position: absolute;
      width: 100%;
      min-width: 312px;
      background-color: transparent;
      border: 0;
      font-family: $font-FuturaLT-Regular;

      .drp-calendar {
        max-width: unset;
        &.left {
          width: 100%;

          .calendar-table {
            padding: 0;
            max-width: 310px;
            margin: auto;
            padding: rem(24) rem(16);
          }
        }
      }

      &.opensright {
        &:after, &:before {
          display: none;
        }
      }

      td {
        font-size: $fs-14;
        text-align: center;


        &.off {
          pointer-events: none;
        }

        &.ends {
          opacity: 0;
          &:hover {
            background-color: unset;
            border-radius: 0;
          }
        }

        &.hover {
          background-color: $color-calendar-active;
          border-radius: 0;
        }

        &.disabled {
          text-decoration: unset;
          color: $color-calendar-disable;
        }

        &.available {
          color: $color-calendar-normal;
          &:hover {
            background-color: $color-calendar-active;
            border-radius: 0;
          }
        }


        &.active {
          background: $color-calendar-active;
        }

        &.start-date, &.end-date {
          border-radius: 0;
        }
      }

      .table-condensed {
        tr {
          &:first-child {
            th {
              padding-bottom: rem(16);
              &:hover {
                background-color: unset;
              }
            }
          }
        }
      }

      .calendar-table {
        td {
          height: 32px;
        }
      }

    }
  }

  &__fixed-button {
    opacity: 0;
    transition: 0.5s;
    width: 105px;
    height: 105px;
    border-radius: 50%;
    position: fixed;
    background: var(--theme-color);
    bottom: 30px;
    right: 30px;
    z-index: $z-index-toplest;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.25);

    div {
      padding: 4px 8px 0;
      color: var(--theme-txt-color);
      text-align: center;
      line-height: 1.4;
      font-family: $font-FuturaLT-Regular;
      font-size: 14px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }

    .icon-up-arrow:before {
      font-size: 25px;
    }
  }

  &__button-text {
    display: none;
  }

  @media #{$media-container-max-1280} {
    &__column {
      padding: 0 rem(12);
      &:first-child {
        padding-left: rem(12);
      }

      &:last-child {
        padding-top: rem(26);
        padding-right: rem(12);
        max-width: 100%;
      }

      &:nth-last-child(2) {
        padding-top: rem(26);
      }
    }

    &__people-bottom {
      justify-content: unset;

      input {
        width: 40px;
      }
    }

    &__destination {
      flex: 1 1 50%;
    }

    &__travel-period {
      flex: 1 1 50%;
    }

    &__people {
      flex: 1 1 50%;
    }

    &__promo-code {
      flex: 1 1 50%;
    }

    &__people-child {
      padding-left: rem(25);
    }
  }

  @media #{$media-not-desktop} {
    &__column {
      padding-top: rem(26);
      &:first-child {
        padding-top: 0;
      }
    }

    &__destination {
      flex: 1 1 100%;
    }

    &__travel-period {
      flex: 1 1 100%;
    }

    &__people {
      flex: 1 1 100%;
    }

    &__promo-code {
      flex: 1 1 100%;
    }
  }

  @media #{$media-screen-mobile} {
    &__people-adult div:first-child:before, &__people-child div:first-child:before {
      bottom: -5px;
  }
    &__content {
      padding: rem(36) rem(32) rem(30);
    }
    &__people-bottom input,&__people-bottom,&__promo-code-bottom input,
    .custom-select2 .select2-selection--single .select2-selection__rendered{
      font-size: $fs-12;

    }
    &_travel-period-bottom,&__promo-code-bottom,&__destination-bottom,&__travel-period-bottom{
      padding-bottom: rem(5);
    }
    &__right {
      display: none;
    }

    &__promo-code {
      display: flex;
      justify-content: space-between;
    }

    &__promo-content {
      flex: 1 1 auto ;
      max-width: calc(100% - rem(32));
    }

    &__button--mobile {
      padding-left: rem(15);
      flex: 0 0 47px;
      display: flex;
      align-items: center;

      .icon-arrow-right-button {
        font-size: 32px;
      }
    }
  }

  &--normal {
    position: unset;
    margin-bottom: $spacing-gutter;
    margin-right: auto;
    margin-left: auto;
    @media #{$media-md} {
      margin-bottom: $spacing-60;
    }

    .booking__content {
      position: unset;
      transform: unset;
    }
  }

}
