.section__card {
  .pa2 {
    display: none;
    position: relative;
    overflow: hidden;
  }

  .mt-n24 {
    margin-top: -$spacing-24;
  }
  .mt-32 {
    margin-top: $spacing-gutter;
  }

  .mt-24 {
    margin-top: $spacing-24;
  }

  .mt-n60 {
    margin-top: -$spacing-60;
  }

  .mt-n150 {
    margin-top: -150px;
  }

  .mt-n80 {
    margin-top: calc(#{-$spacing-40} * 2);
  }

  .mt-n40 {
    margin-top: -$spacing-40;
  }

  .card__title {

    & p {
      color: var(--theme-txt-second-color);
      font-size: $fs-24;
    }
  }

  @media #{$media-screen-mobile} {
    .icon-view-all svg {
      width: calc(#{$spacing-16} * 2);
      height: calc(#{$spacing-16} * 2);
    }
    .item-card__content-bg {
      & .item-card__content {
        & h3 {
          font-size: $fs-28;
        }
      }
    }

  }
  @media #{$media-md} {
    .scroll-main {
      display: flex;
      overflow: hidden;

      & .item-card {
        flex: 0 0 auto;
      }
    }
  }
}

.js-horizontal-scroll {
  & .js-scroll-active {
    display: flex;
    margin: 0 calc(#{-$spacing-16} / 2);
    overflow: hidden;

    & .item-card {
      padding: 0 calc(16px / 2);
      flex: 0 0 auto;
    }
  }

}

@media #{$media-screen-mobile} {
  .section__card {
    .card__title {
      & p {
        font-size: $fs-18;
      }
    }
  }

  .list-card {
    & .item-card__content {
      & p {
        font-size: $font-base-2;
      }
    }

  }
}
