.reviews {
  a {
    text-decoration: none;
  }
  p {
    text-transform: inherit;
    font-family: $font-NimbusSans;
  }
  .section-item-dining__head {
    font-family:$font-NimbusSans;
    color: $bg-color-white;
    margin-bottom: $spacing-gutter;
    @extend .d18-m16;
    text-align: center;

    &-title{
      font-family: $font-NimbusSans-Bold;
      line-height: normal;
      text-transform: uppercase;
      @extend .d40-m28;
      span{
        color: var(--theme-color);
      }
    }
  }

  .list-reviews {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: calc(#{$spacing-16} /2);
    .item-reviews {
      background-color: var(--theme-txt-bg-color);
      width: calc(100% / 3 - #{$spacing-16} / 3);
      & .item-reviews__content {
        color:$color-black-272727;
        padding:  $spacing-24 $spacing-16;
        font-family: $font-NimbusSans;
        .title-country-dining{
          @extend .d25-m16;
          font-family: $font-NimbusSans-Bold;
          text-transform: uppercase;
          @extend .d25-m16;
        }
        .des-country-dining,.author{
          @extend .d18-m14;
          margin-top: calc(#{$spacing-16} / 2);
          text-transform: inherit;
        }
      }
    }
  }
  @media #{$media-not-desktop} {
    .list-reviews .item-reviews {
      width: calc(50% - 4px);
    }
  }
  @media #{$media-screen-mobile} {
    .list-reviews{
      gap:0;
      .item-reviews{
        width: 100%;
        margin-bottom: calc(#{$spacing-16} / 2);
        .item-reviews__content{
          padding: $spacing-16;
        }
      }
    }
  }
}
