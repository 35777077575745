.rooms-homepage{
    .box-roomspage{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        h1{
            color: $color-secondary;
            padding: rem(10);
        }
        i{
            color: $color-secondary;
            font-size: $fs-60;
            border-radius:50% ;
        }
    }
    .list-content{
        display: flex;
        justify-content: center;
        .slide-rooms-homepage{
            padding: rem(20);
            margin: auto;
            .bgr-top{
                
            }
        }
    }
}