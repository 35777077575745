@font-face {
  font-family: 'FuturaLT';
  src: url($font-path + 'fonts/FuturaLT.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaLT-Regular';
  src: url($font-path + 'fonts/FuturaLT.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura-Medium';
  src: url($font-path + 'fonts/FuturaLT.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaLT-Bold';
  src: url($font-path + 'fonts/FuturaLT-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaLT-Book';
  src: url($font-path + 'fonts/FuturaLT-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaLT-Heavy';
  src: url($font-path + 'fonts/FuturaLT-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaLT-LightOblique';
  src: url($font-path + 'fonts/FuturaLT-LightOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaLT-Oblique';
  src: url($font-path + 'fonts/FuturaLT-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'StarlitDrive';
  src: url($font-path + 'fonts/StarlitDrive.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url($font-path + 'fonts/HelveticaNeueLTStd-Lt.otf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeue-Regular';
  src: url($font-path + 'fonts/HelveticaNeueLTStd-Lt.otf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url($font-path + 'fonts/HelveticaNeueLTStd-Bd.otf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueLTStd-65Medium';
  src: url($font-path + 'fonts/HelveticaNeueLTStd-Bd.otf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueLTStd-55Roman';
  src: url($font-path + 'fonts/HelveticaNeueLTStd-Lt.otf') format('truetype');
}
@font-face {
  font-family: 'NimbusSanL-Bol';
  src: url($font-path + 'fonts/NimbusSanL-Bol.otf') format('truetype');
}
@font-face {
  font-family: 'NimbusSanL-Reg';
  src: url($font-path + 'fonts/NimbusSanL-Reg.otf') format('truetype');
}
@font-face {
  font-family: 'DS-Digi';
  src: url($font-path + 'fonts/DsDigi.ttf') format('truetype');
}
@font-face {
  font-family: 'Goldman-Sans-Rg';
  src: url($font-path + 'fonts/GoldmanSans_Rg.ttf') format('truetype');
}
@font-face {
  font-family: 'Goldman-Sans-Bd';
  src: url($font-path + 'fonts/GoldmanSans_Bd.ttf') format('truetype');
}
@font-face {
  font-family: 'Goldman-Rg';
  src: url($font-path + 'fonts/Goldman-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Goldman-Bd';
  src: url($font-path + 'fonts/Goldman-Bold.ttf') format('truetype');
}



@mixin textStyle-d36-m24 {
  font-size: 36px;
  line-height: 1.16;
  font-family: $font-objectSans-regular;

  @media #{$media-screen-mobile} {
    font-size: 24px;
    line-height: 1.25;
  }
}

@mixin textStyle-p {
  font-size: 18px;
  line-height: 1.44;
  font-family: $font-objectSans-light;

  @media #{$media-screen-mobile} {
    font-size: 16px;
    line-height: 1.375;
  }
}

@mixin textLink {
  font-size: 16px;
  line-height: 1.5;
  font-family: $font-objectSans-light;
}

