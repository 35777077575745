.footer {
  width: 100%;

  a {
    text-decoration: none;
  }

  &__body {
    max-width: 800px;
    margin: 0 auto;
    padding: $padding-component;

    @media #{$media-screen-mobile} {
      padding: $padding-component-mb;
    }
  }

  &__subcribe {
    text-align: center;
    color: var(--theme-txt-second-color);
    .heading-main{
      font-family: $font-NimbusSans-Bold;
      @extend .d60-m40;
    }
    .sub-title {
      @extend .d18-m16;
      margin-bottom: $spacing-gutter * 0.5;
      font-family: $font-NimbusSans;
    }

    .title {
      @extend .d60-m40;
      margin-bottom: $spacing-gutter * 0.5;
      text-transform: uppercase;
    }
  }

  &__form {
    margin-top: 1rem;
    .form-group {
      display: flex;
      justify-content: center;

      &__input {
        min-width: 300px;
        position: relative;
        display: flex;
      }

      &__error {
        position: absolute;
        color: $color-error;
        font-size: $fs-18;
        top: calc(100% + 10px);
        left: 0;
      }

      &__api-message {
        display: none;
        color: $color-white;
        font-size: $fs-18;
        padding-top: rem(30);
        text-align: center;
      }

      input {
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $color-white;
        border-radius: 0;
        outline: none;
        padding: 0;
        color: var(--theme-txt-second-color);
        font-family: $font-NimbusSans;
        font-size: $fs-18;
        &::placeholder {
          color: var(--theme-txt-second-color);
        }

        &:focus {
          box-shadow: none;
          color: $color-white;
        }
      }

      button {
        font-family: $font-NimbusSans;
        margin-left: $spacing-md;
        color: var(--theme-txt-color);
        background-color: var(--theme-color);;
        font-size: $fs-12;
        letter-spacing: 1.8px;
      }
    }

    @media #{$media-screen-mobile} {
      .form-group {
        flex-direction: column;

        input {
          max-width: 100%;
        }
        button {
          margin-top: $spacing-md;
          display: inline-block;
          margin-left: 0;
          width: max-content;
          margin-inline: auto;
        }
      }
    }
  }
  &__bottom {
    position: relative;
    .footer__bottom-bg{
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      background-position: right;
      background-size: contain;
    }
    .container-fluid{
      padding:0;
    }
    &-flex {
      padding: $spacing-gutter*0.5 $spacing-md;
      display: flex;
      align-items: end;
      -webkit-align-items: flex-end;
    }

    @media #{$media-screen-mobile} {
      min-height: 290px;
      &-flex {
        flex-direction: column;
  
      }
      .footer__bottom-bg{
        background-position-y: bottom;
        background-repeat: repeat-x;
      }
    }
  }

  &__logo {
    margin-right: 60px;
    z-index: 1;

    a {
      @extend .d35-m28;
      color: $color-black;
      div {
        height: 34px;
      }
    }

    @media #{$media-screen-mobile} {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $spacing-gutter;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    z-index: 1;
    flex-wrap: wrap;

    li {
      margin-right: 40px;
      margin-top: $spacing-8;

      a {
        color: $color-black;
        font-family: $font-NimbusSans;
        @extend .d18-m16;
      }
    }

    @media #{$media-screen-mobile} {
      justify-content: center;
      li {
        text-align: center;
        margin: $spacing-8 $spacing-gutter * 0.5;
      }
    }
  }
}

.footer__bottom,.footer__bottom-flex {
  background-color: var(--theme-color);
}
.bg-blue-16abff{
  .footer__bottom,.footer__bottom-flex {
    background-color: $color-16abff;
  }
}