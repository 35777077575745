.venues-country{
  .item-card{
    a{
      cursor: initial;
    }
    @media #{$media-md} {
      margin-bottom: $spacing-48;
    }
    .item-card__container{
      .item-card__content{
        max-width: 420px;
        .title{
          @extend .d40-m28;
          line-height: normal;
          margin-bottom: 0.5rem;
          text-transform: uppercase;
        }
        .title-des{
          @extend .d18-m14;
          font-family: $font-NimbusSans-Bold;
          line-height: normal;
          margin-bottom: 0.5rem;
        }
        .desc{
          @extend .d18-m14;
          line-height: normal;
        }
      }
      .bgcolor-img{
        max-width: 1000px;
        position: relative;
      }
    }
    &__img{
      .pa1{
        padding-top: 50%;
      }
    }
  }
  @media #{$media-lg} {
    .item-card{
      &:nth-child(odd){
        .item-card__container{
          margin-left: auto;
          padding-top:  $spacing-56;
          padding-right:  $spacing-56;
          padding-left: 176px;
          .bgcolor-img{
            margin-left: auto;
          }
          .item-card__bgImg{
            top: -$spacing-56;
            left: $spacing-56;      
          }
          .item-card__content{
            bottom: 80px;
            left: -176px;
          }
        }
      }
      &:nth-child(even){
        .item-card__container{
          margin-right: auto;
          padding-bottom:  $spacing-56;
          padding-left:  $spacing-56;
          padding-right: 176px;
          .item-card__bgImg{
            top: $spacing-56;
            left: -$spacing-56;      
          }
          .item-card__content{
            top: 80px;
            right: -176px;
            left: inherit;
          }
        }
      }
    }
  }

  @media #{$media-screen-tablet} {
    .item-card{
      .item-card__container{
        padding-top: $spacing-24;
      }
      &:nth-child(odd){
        .item-card__container{
          padding-right: $spacing-24;
          padding-left: 100px;
          padding-top: $spacing-24;
          .item-card__bgImg{
            top: -$spacing-24;
            left: $spacing-24;
          }
          .item-card__content{
            bottom: $spacing-24;
            left: -100px;
          }
        }
      }
      &:nth-child(even){
        .item-card__container{
          padding-bottom: 0;
          padding-left: $spacing-24;
          padding-right: 100px;
          .item-card__bgImg{
            top: -$spacing-24;
            left: -$spacing-24;
          }
          .item-card__content{
            top: $spacing-24;
            right: -100px;
          }
        }
      }
    }
  }
}
