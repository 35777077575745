.dine-ws {
  display: flex;
  opacity: 1;

  &--opacity{
  opacity: 1;
      path{
          fill: $color-secondary;
      }
      .pa1{
          background-image: unset !important;
          background: $color-secondary !important;
      }
      .container{
          display: block;
              .dine-ws__title{
                  margin: auto;
                  .heading-section{
                      &__title span{
                          color:$color-secondary;
                      }
                  }
              }
              .item-card__content{
                  background: $color-white;
              }
              .list-dine-ws{
                  width: 100%;
                  .lst-dine-ws-box{
                      width: 100%;
                  }
          }
      }
  }

  .lst-dine-ws-box {
    display: flex;
    width: 100%;
  }

  @media #{$media-md} {
    transition-timing-function: ease;
    transition-duration: 0.4s;
    opacity: 0;
  }

  .container {
    display: flex;
  }

  .card__title {
    width: 35%;
    min-width: 35%;
  }

  .list-dine-ws{
      width: 100%;
      position: relative;
  }

  @media #{$media-screen-mobile} {
    flex-direction: column;
    .container {
      display: block;
      padding: 0;
    }

    .card__title {
      width: 100%;
      text-align: center;
    }
  }

  @include swiper-navigation;

}


