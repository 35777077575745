.insider-exclusive {
  h1,h2,h3{
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;

  }
  .heading-section {
    text-align: center;
  }
  .gallery__title, .rich-text p strong {
    color: var(--theme-color);
  }
}
