
@mixin rewards-staycation-item{
    width: calc(100% / 3);
    padding:0 calc($spacing-16 / 2);
    text-align: center;
    margin-bottom: $spacing-16;
    &__container{
        height: 100%;
        background-color: $color-white;
        padding:  calc($spacing-16 * 2) $spacing-16;
    }
    &:nth-child(odd) &__container{
        background-color: var(--theme-color);
    }

    &__img {
        display: block;
        margin-bottom: calc($spacing-16 / 2);
        padding-top: calc(100% * 60/352);
        position: relative;
        overflow: hidden;
        img{
          max-height: 100%;
          position: absolute;
          top:50%;
          transform: translate(-50%,-50%);
          object-fit: contain;
        }
    }

    &__content{
        font-family: $font-NimbusSans;
        color: var(--theme-color);
        .title{
            @extend .d30-m20;
            color: var(--theme-color);
            margin-bottom: $spacing-8;
            text-transform: uppercase;

        }
          .desc{
            @extend .d16-m14;
        }
    }

    &:nth-child(odd) &__img{
        filter: brightness(0) invert(1);

    }
    &:nth-child(odd) &__content{
        color: $color-white;
        .title{
            color: $color-white;
        }
    }

    @media #{$media-md} {
        &__container{
            padding: calc($spacing-16 * 3) calc($spacing-16 * 2);
        }
        &__img {
            margin-bottom: $spacing-16;
        }
        .title{
            margin-bottom: $spacing-16;
        }
    }
    @media #{$media-screen-mobile} {
        width: 100%;
        padding:0;
        &__img {
            padding-top: calc(100% * 40/191);
        }
        &.swiper-slide{
            width: 62%;
            margin-right: calc($spacing-16 / 2);
            margin-bottom: 0;
            flex: 0 0 auto;
            &:last-child{
                margin-right: 0;
            }
        }
        &__container{
            padding:  $spacing-24 $spacing-16;
        }
        &__img {
            margin-bottom: $spacing-16;
        }
    }

}
