.dine {
  .heading-section {
    text-align: center;
  }
  .container {
    max-width: calc(#{$spacing-960} + #{$spacing-30});
  }
  .card__title {
    margin-bottom: 74px;
  }
  .list-dine {
    & .item-card {
      margin-bottom: calc(#{$spacing-40} + #{$spacing-56});
      max-width: 800px;

      &:nth-child(2n + 1) {
        margin-left: auto;
      }

      & .item-card__content-bg {
        margin-left: $spacing-24;
        margin-right: $spacing-24;

        & .item-card__img {
          & .pa1 {
            padding-top: 60.44%;
          }
        }

        & .item-card__content {
          h5 {
            margin: calc(#{$spacing-16} / 2) 0;
            font-family: $font-NimbusSans-Bold;
          }
          p{
            font-family: $font-NimbusSans;
          }
          h5, p {
            font-size: 18px;
          }
        }
      }
    }
  }
  
  @media #{$media-screen-tablet-desktop} {
    .container {
      max-width: calc(100% - 4rem);
    }
  }
  @media #{$media-screen-mobile} {
    .container {
      padding: 0 calc(#{$spacing-16} / 2);
    }
    .card__title {
      margin-bottom: $spacing-48;
    }
    .list-dine {
      & .item-card {
        margin-bottom: $spacing-24;
        .item-card__content-bg {
          margin: $spacing-16 $spacing-16 0 0;
          text-decoration: none;
          .bgcolor-img {
            position: relative;
          }
          .item-card__bgImg {
            top: -$spacing-16;
            left: $spacing-16;
          }
          & .item-card__content {
            top: calc(100% - #{$spacing-55});
            max-width: calc(100% - #{$spacing-16} * 2);
            left: $spacing-16;
            bottom: inherit;
            right: inherit;
            width: 100%;
            h5, p {
              font-size: $spacing-14;
            }
          }
        }
      }
      & .bg-position-bottom-left-mbr-56{
        .item-card__content-bg {
          .bgcolor-img {
            .item-card__img {
              margin-left: $spacing-16;
              width: 100%;
            }
            .item-card__bgImg {
                left: 0;
            }
          }
          .item-card__content {
            left: calc(#{$spacing-16} * 2);
          }
        }
      }

    }
  }

  
}
