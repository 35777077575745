
@mixin heading-section--stb-campaign-site{
  color: $color-white;
  text-align: center;
  margin: 0 auto;
  margin-bottom: $spacing-40;
  max-width: 1000px;
  .heading-section__title{
    margin-left: auto;
    margin-right: auto;
    @extend .d40-m28;
  }
  .heading-section__desc{
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
    @extend .d20-m16;
    a{
      font-family: $font-NimbusSans-Bold;
      color: $color-16abff;
    }
  }
  .heading-section__btnBook {
    margin-top: $spacing-24;
  }

  @media #{$media-screen-mobile} {
    margin-bottom: calc($spacing-16 * 2);
  }
}

.heading-section {
  $this:&;
  color: var(--theme-color);
  path{
    fill:  var(--theme-color);
  }
  ellipse{
    fill: var(--theme-txt-color);
  }
  .icon-view-all svg,.icon-arrow-right-button{
    display: inline-block;
    transform: #{"/*!rtl:rotate(180deg)*/"} inherit;
  }
  &__title {
    font-family: $font-futuraLT-bold;
    margin-bottom: $spacing-8;
    @extend .d60-m40;
    line-height: normal;

    h1,h2{
      letter-spacing: inherit;
      line-height: inherit;
      font-size: inherit;
      display: inline-block;
    }

    a {
      color: unset;
      text-decoration: none;
    }

  }
  &__subtitle {
    font-family: $font-NimbusSans-Bold;
    @extend .d40-m28;
    line-height: normal;
    margin-bottom: rem(8);

    h2{
      letter-spacing: inherit;
      line-height: inherit;
      font-size: inherit;
      display: inline-block;
    }

    a {
      color: unset;
      text-decoration: none;
    }

  }

  &__desc {
    font-family: $font-NimbusSans;
    margin-bottom: $spacing-16;
    @extend .d24-m18;
    color: var(--theme-txt-second-color);
    line-height: normal;
  }
  &__contact{
    #{$this}__contact-item{
      display: inline-block;
    }
    #{$this}__contact-email{
      margin-left:  calc(#{$spacing-16} * 2);
    }
    a{
      color: var(--theme-txt-second-color);
      text-decoration: none;
    }
    .icon-email,.icon-phone{
      transform: translateY(3px);
      display: inline-block;
    }
  }
  &__btnBook {
    margin-top: $spacing-24;

    a {
      color: var(--theme-txt-color);
      font-size: $fs-12;
      background-color: var(--theme-color);
      padding: $spacing-12 $spacing-24;
      display: inline-block;
      letter-spacing: 1.2px;
      border-radius: 50px;
      font-family: $font-Futura-Medium;
      text-decoration: none;
    }
  }

  @media #{$media-screen-mobile} {
    &__contact{
      #{$this}__contact-item{
        display: block;
        margin-left: 0;
      }
    }
    &__desc{
      margin-bottom: $spacing-16;
    }
    &__btnBook {
      margin-top: $spacing-16;
    }
    &__title{
      margin-bottom: $spacing-16;
    }
  }

}
.introduction--stb-campaign-site,
.activation-key-features--stb-campaign-site,
.prizes--stb-campaign-site,
.rewards-staycation--stb-campaign-site,
.how-to-play--stb-campaign-site,
.faq--stb-campaign-site,
.tearms-and-conditions--stb-campaign-site    {
  @include mb-section;
  .heading-section{
    @include heading-section--stb-campaign-site;
  }
}
.introduction--stb-campaign-site{
  .heading-section{
    @include heading-section--stb-campaign-site;
    .heading-section__title{
      color: $color-16abff;
      max-width: 770px;
      @include d60-m40;
    }
    .heading-section__desc{
      max-width: 1020px;
  
    }
  }
}
.introduction--error-page{
  padding-top: 141px;
  overflow: unset;
  padding-bottom: calc($spacing-120 - $spacing-16);
  text-align: center;
  margin-bottom: 0;
  .heading-section{
    max-width: 725px;
    margin: auto;
    &__title{
      margin-bottom: 23px;
    }
    &__desc{
      font-family: $font-HelveticaNeue-Light;
    }
  }
  
  @media #{$media-screen-mobile} {
    padding-bottom: calc($spacing-60 - $spacing-16);
    .heading-section{
      max-width: unset;
    }
  }
}
