.image-library {
  overflow: hidden;

  &__top {
    background: var(--theme-msocial-bg-color);
    border-top-left-radius: 250px;
    border-top-right-radius: 250px;

    // TODO: check if correct
    background-image: url($assets-path + '/template-images/ms-small-pattern.png');
    background-repeat: repeat;
    background-size: 83px;

    .mb-header{
      .text-uppercase span{
        color: var(--theme-msocial-txt-color);
      }
  }
  }

  .heading-section {
    margin-bottom: 0;
    font-size: $fs-60;
    text-align: center;
    color: var(--theme-msocial-txt-color);
    font-family: $font-futuraLT-bold;
    padding: rem(80) 0;
  }

  &__content {
    background: var(--theme-msocial-bg-color);

    // TODO: check if correct
    background-image: url($assets-path + '/template-images/ms-small-pattern.png');
    background-repeat: repeat;
    background-size: 80px;
  }

  &__container-slide {
    flex-direction: column;
    position: relative;
    @include swiper-navigation;
  }

  &__row {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 16px;
  }

  &__row::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  &__image {
    width: auto;
    padding-bottom: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__image-even {
    margin-bottom: rem(16);
  }

  &__image-odd {
    margin-top: rem(16);
  }

  &__column {
    max-width: 296px;
    margin: 0 rem(8);

    &:first-child {
      margin: 0 rem(8) 0 0;
    }

    &:last-child {
      margin: 0 0 0 rem(8);
    }
  }


  @media #{$media-screen-mobile} {
    &__top {
      border-top-left-radius: 55px;
      border-top-right-radius: 55px;
    }

    .heading-section{
      padding: 0.4rem;
    }

    &__content{
      padding-top: $spacing-24;
    }

    &__title {
      font-size: $fs-40;
      padding: rem(20) 0;
    }

    &__container {
      padding-top: rem(24);
      padding-bottom: $padding-component-mb;
      transform: unset !important;
    }

    &__row {
      flex-wrap: wrap;
      margin-bottom: 0;
      flex-direction: column;
      justify-content: space-between;
    }

    &__row:nth-child(n+2) {
      display: none;
    }

    &__image:nth-child(n+7) {
      display: none;
    }

    &__column {
      max-width: unset;
      display: flex;
      justify-content: center;
      margin: 0;

      &:nth-child(n+4) {
        display: none;
      }

    }

    &__image-even, &__image-odd {
      margin: unset;
    }

    &__image {
      width: 50%;
      max-width: 50%;
      padding-bottom: 50%;

      &:first-child {
        margin-right: rem(10);
        margin-bottom: rem(20);
      }

      &:last-child {
        margin-left: rem(10);
        //padding-top: rem(20);
        margin-top: rem(20);
      }

    }

  }

  &__gallery {
    .image-library__top {
      .mb-header{
        .text-uppercase span{
          color: var(--theme-color);
        }
      }
    }

    .heading-section__title {
      display: inline-flex;
      align-items: center;
    }

    .icon-view-all svg {
      display: none;
    }
    
    .image-library__top {
      background: transparent;
      background-image: none;
    }
  
    .image-library__content {
      background: transparent;
      background-image: none;
    }
  
    .image-library__column {
      max-width: 400px;
      margin: 0 0.75rem;
    }
  
    .image-library__image:nth-child(2) {
      display: none;
    }
  
    .image-library__image.image-library__image-odd {
      margin-top: 2.5rem;
    }
  
    @media #{$media-screen-mobile} {
      .icon-view-all svg {
        display: block;
        width: 32px;
        height: 32px;
        margin-left: 1rem;
      }
      .image-library__content {
        width: calc(100% - 30px);
        margin-left: 15px;
      }
  
      .image-library__column {
        margin: 0 0.5rem;
      }
  
      .image-library__column:nth-child(n+4){
        display: flex
      }
  
      .image-library__image {
        width: 100%;
        padding-bottom: 100%;
        max-width: 100%;
      }
  
      .image-library__image:first-child {
        margin-right: unset;
      }
  
      .image-library__image.image-library__image-odd {
        margin: unset;
        margin-top: 1.25rem;
      }
  
      .image-library__row {
        flex-wrap: nowrap;
        flex-direction: row;
      }
    }
  }
}