.how-to-play{
    .heading-section{
        width: 45%;
        min-width: 45%;
        padding-right: calc($spacing-16 * 2 + $spacing-16 /4);
    }
    &__list{
        @include how-to-play__list;
    }
    &__item{
        @include how-to-play__item;
    }
    @media #{$media-lg} {
        &__container{
            display: flex;
            align-items: center;
        }
        .heading-section{
            text-align: left;
            margin-bottom: 0;
        }
    }
    @media #{$media-not-desktop} {
        &__list{
            width: 100%;
        }
        .heading-section{
            width: 100%;
            padding-right: 0;
        }
    }
    @media #{$media-screen-mobile} {
        &__container{
            margin-right: calc(#{-$spacing-16} + 1px);
        }
        .heading-section{
            padding-right: calc($spacing-16 - 1px);
        }
    }
}