@use 'sass:math';

.country-thingstodo{
    h2{font-size: inherit;}
     .heading-section {
      text-align: center;
      max-width: 900px;
      margin-right: auto;
      margin-left: auto;
      &__title {
        margin-bottom: 0.5rem;
      }
    }
    .thingstodo__list{

        display: flex;
        flex-wrap: wrap;
        margin: 0 calc(#{-$spacing-24} / 2);
        .item-card{
          display: block;
          margin-bottom: calc(#{$spacing-24} * 2);
          &__img{
            .pa1{
              padding-top: 67.16%;
            }
          }

          &.bg-position-top-center{
            .item-card__bgImg{
              .pa1{
                padding-top: 22.5%;
              }
            }
          }
          .item-card__content {
            padding: $spacing-16 ;
            p{
              margin-top: calc(#{$spacing-16} / 2) ;
              font-family: $font-NimbusSans;
              @extend .d20-m14
            }

            h3 {
              font-family: $font-NimbusSans-Bold;
              font-weight: bold;
              @extend .d35-m28;
            }
          }
          &.no-has-image{
            margin-bottom: $spacing-24;
            .item-card__content {
              max-width: 100%;
              background-color: var(--theme-color);
              .desc{
                @media #{$media-screen-mobile} {
                  font-size: $fs-18;
                }
              }
            }
          }
          &:last-child{
            margin-bottom: 0;
          }

        }
        .col-left{
            width: 66%;
            padding: 0 calc(#{$spacing-24} / 2);

        }
        .col-right{
            width: 34%;
            padding: 0 calc(#{$spacing-24} / 2);
            .item-card {
              &__img {
                .pa1 {
                  padding-top: 133.66%;
                  @media #{$media-not-desktop} {
                    padding-top: 67.16%;
                  }
                }
              }
            }
        }
    }
    .map {
      br {
        display: none;
      }
    }


  @media #{$media-not-desktop} {
    .map {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__container {
        padding-bottom: percentage(math.div(203, 344));
      }

      &__inf-detail {
        padding-left: rem(30);
      }

      &__detail {
        display: inline-block;
        position: unset;
        width: fit-content;
        margin-top: -40px;
        z-index: 2;
      }
      br {
        display: block;
      }
    }
    & .thingstodo__list .col-left,
    & .thingstodo__list .col-right{
      width: 100%;
    }
    & .thingstodo__list {
      & .item-card{
        display: flex;
        flex-direction: column;
        margin-bottom:  $spacing-16;
        & .item-card__content{
          max-width: calc(100% - #{$spacing-16} * 2);
          left: $spacing-16;
          padding: $spacing-16;
          bottom: inherit;
          top: calc(100% - #{$spacing-60});
        }

        &.no-has-image{
          margin-bottom: $spacing-16;
        }
      }
      .col-right{
        margin-top: $spacing-16;
      }

    }

  }
}
