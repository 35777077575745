@use "sass:math";

.rich-text {
  max-width: 1040px;
  margin: 0 auto;
  //font-family: $font-HelveticaNeue-Light;
  margin-bottom: $spacing-gutter;

  @media #{$media-md} {
    margin-bottom: $spacing-60;
  }

  @include rich-text-editor;

}

.tearms-and-conditions--stb-campaign-site,
.prizes--stb-campaign-site,
.rewards-staycation--stb-campaign-site{
  .rich-text{
    max-width: initial;
    margin-bottom: initial;

    @media #{$media-md} {
      margin-bottom: initial;
    }
  }
}

