@use "sass:math";

.card-venues {
  position: relative;
  background-repeat: no-repeat;
  padding-bottom: 12px;
  padding-right: 12px;
  background-position-x: 24px;
  background-position-y: 24px;
  margin-bottom: $spacing-80;
  color: $color-black-272727;
  text-decoration: none;
  display: block;

  h1,h2,h3,h4{
    font-size: inherit;
  }

  @media #{$media-md} {
    padding-bottom: 24px;
    padding-right: 24px;

  }

  &:hover {
    .card-venues__img {
      overflow: hidden;

      img {
        transform: scale(1.05);
        transition: .3s;
      }
    }

  }

  &__img {
    font-size: 0;
    overflow: hidden;
    img {
      transition: .3s;
      max-width: 344px;
      @media #{$media-md} {
        max-width: 546px;
      }
    }
  }
  &__bgImg {
    position: absolute;
    left: $spacing-24;
    width: 100%;
    height: 100%;
    top: $spacing-24;
    z-index: -1;
    background-size: cover;
    @media #{$media-screen-mobile} {
      left: $spacing-16;
      top: -$spacing-16;
    }
  }
  &__content{
    @media #{$media-screen-mobile} {
      left: $spacing-16;
    }
  }
  &__wrap-odd{
    .card-venues__bgImg {
      @media #{$media-screen-mobile} {
        left: -$spacing-16;
      }
    }

  }
  &__text-box {
    position: absolute;

    .card-venues__content {
      width: 312px;
      margin: auto;
      padding: 16px;
      background-color: var(--theme-txt-bg-color);
      position: absolute;
      @media #{$media-md} {
        width: fit-content;
        margin: unset;
      }
    }

    width: 100%;
  }

  &__title {
    font-family: $font-NimbusSans-Bold;
    font-size: $fs-28;
    line-height: normal;
    @media #{$media-md} {
      font-size: $fs-35;
    }
  }

  &__sub-title {
    font-family: $font-NimbusSans-Bold;
    font-size: $fs-14;
    line-height: normal;
    margin-bottom: 0.5rem;
    @media #{$media-md} {
      font-size: $fs-18;
    }
  }

  &__capacity {
    font-size: $fs-14;
    line-height: normal;
    margin-bottom: 0.5rem;
    @media #{$media-md} {
      font-size: $fs-18;
    }
  }

  &__desc {
    font-size: $fs-14;
    line-height: normal;
    font-family: $font-NimbusSans;
    @media #{$media-md} {
      font-size: $fs-18;
    }
  }

  &.text-box-top-left {
    .card-venues__text-box {
      top: 195px;
      left: unset;
      width: 344px;
      @media #{$media-md} {
        width: calc(100% - 24px);
        top: -45px;
        left: -24px;
      }
    }
  }

  &.text-box-bottom-left {
    .card-venues__text-box {
      .card-venues__content{
        bottom: 0;
      }
      @media #{$media-md} {
        bottom: 24px;
        left: -53px;
      }
      @media #{$media-lg} {
        bottom: 80px;
      }

      @media #{$media-container-1280} {
        left: -192px;
      }
    }
  }

  &.text-box-top-right {
    .card-venues{
      &__bgImg{
        left: -56px;
        top: 56px;
      }
    }
    .card-venues__text-box {
      .card-venues__content{
        bottom: inherit;
      }
      @media #{$media-md} {
        top: 24px;
        right: -53px;
      }
      @media #{$media-lg} {
        top: 80px;
      }
      @media #{$media-container-1280} {
        right: -192px;
      }
    }
  }

  &.bg-top-right {
    margin-top: 56px;
    margin-bottom: 48px;
    padding-right: 15px;
    padding-bottom: 0;
    background-position-x: 15px;
    background-position-y: -328px;
    @media #{$media-md} {
      padding-right: 24px;
      background-position-y: -194px;
    }
    @media #{$media-lg} {
      background-position-y: -74px;
    }
    @media #{$media-container-1280} {
      background-position-x: 24px;
      background-position-y: 0;
    }
  }

  &.bg-bottom-left {
    margin-bottom: calc(56px + 48px);
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 15px;
    background-position-x: -655px;
    background-position-y: -328px;
    @media #{$media-md} {
      padding-left: 24px;
      padding-right: 0;
      padding-top: 0;
      background-position-x: -389px;
      background-position-y: 24px;
    }
    @media #{$media-lg} {
      background-position-x: -149px;
    }
    @media #{$media-container-1280} {
      background-position-x: 0;
    }
  }
}
