// container
.container {
  @media #{$media-container-1280} {
    padding: 0 rem(24);
    max-width: $container-max-width;
  }

  @media #{$media-screen-mobile} {
    max-width: 100%;
  }
}
.logo-region{
  .container {
    @media #{$media-container-1280} {
      max-width: calc(1168px + 2 * rem(24));
    }
  }
}
.destinations {
  .container {
    @media #{$media-container-1280} {
      padding: 0 rem(32);
      max-width: calc(1600px + rem(32));
    }
  }
}

.title {
  font-family: $font-futuraLT-bold;
  font-size: $fs-28;
  line-height: normal;
  @media #{$media-md} {
    font-size: $fs-25;
  }
  @media #{$media-lg} {
    font-size: $fs-35;
  }
}


.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

// text utilities
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.flex-center {
  justify-content: center;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.flex-middle {
  align-items: center;
}

// transform class using with element position not static
.transform-middle {
  top: 50%;
  transform: translateY(-50%);
}

.transform-center {
  left: 50%;
  transform: translateX(-50%);
}

.transform-mc {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item-fullfill {
  flex: 1 0 auto;
}

.small, small {
  font-size: 80%;
}

.text-ellipsis {
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.hidden-scroll {
  overflow: hidden;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-semi-bold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  font-size: 12px;
  letter-spacing: 1.2px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all .3s linear;
  line-height: 1;

  &.btn-primary {
    color: $color-white;
    background-color: $color-primary;
  }
}

.d60-m40 {
  @include d60-m40;
}
.d48-m32 {
  font-size: 48px;
  @media #{$media-screen-mobile} {
    font-size: 32px;
  }
}
.d40-m28 {
  font-size: 40px;
  @media #{$media-screen-tablet} {
    font-size: 25px;
  }
  @media #{$media-screen-mobile} {
    font-size: 28px;
  }
}

.d35-m28 {
  @include d35-m28;
}
.d35-m20 {
  @include d35-m20;
}
.d30-m24 {
  @include d30-m24;
}
.d30-m20 {
  @include d30-m20;
}
.d25-m20 {
  @include d25-m20;
}

.d25-m16 {
  font-size: 25px;

  @media #{$media-screen-mobile} {
    font-size: 16px;
  }
}

.d24-m18 {
  @include d24-m18;
}
.d20-m16 {
  @include d20-m16;
}
.d20-m14 {
  font-size: 20px;

  @media #{$media-screen-mobile} {
    font-size: 14px;
  }
}
.d18-m16 {
  @include d18-m16;
}

.d18-m14 {
  @include d18-m14;
}
.d16-m14 {
  @include d16-m14;
}

@keyframes menuAnimation {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}

.custom-aos-animate {
  opacity: 1 !important;
  transition-property: opacity, transform;
}

//custom for swiper scroll

.custom-swiper {
  overflow: hidden !important;
  cursor: pointer;
}

.mt-24{
  margin-top: $spacing-24;
}

.mt-78{
  margin-top:  $spacing-78;

  @media #{$media-screen-mobile} {
    margin-top: $spacing-16;
  }
}

//swiper navigation
@mixin swiper-navigation {
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 40px;
    color: var(--theme-color);
    z-index: 2;

    .icon {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 40%;
        height: 40%;
        margin: auto;
        background: white;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
      }
    }
    &-left {
      left: 32px;
      transition: all 0.3s linear;
    }

    &-right {
      right: 32px;
      transition: all 0.3s linear;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
    i{
      display: inline-block;
      transform: #{"/*!rtl:rotate(180deg)*/"} inherit;
    }

    @media #{$media-screen-mobile} {
      display: none;
    }
  }
}

.msocial-merlion-package,
.how-to-play--stb-campaign-site,
.image-library--stb-campaign-site {
  .arrow{
    color: $color-16abff;
    .icon-global-arrow-left:before,
    .icon-global-arrow-right:before {
      color: $color-16abff;
    }
  }
}
