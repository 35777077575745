@mixin rich-text-editor {
  a{
    color: var(--theme-color);
  }
  //default color
  $heading-color: $color-primary;
  $description-color: $color-white;

  h1, h2, h3, h4, h5, .body-1, .body-2, .body-3 {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-bottom: $spacing-16;
  }

  h1 {
    color: var(--theme-color, $heading-color);
    padding-bottom: $spacing-16;
  }

  h2, h3, h4, h5 {
    color: var(--theme-txt-second-color, $description-color);
    padding-bottom: $spacing-16;
  }

  h1 {
    font-family: $font-futuraLT-bold;
    @include d60-m40;
  }

  h2 {
    font-family: $font-futuraLT-bold;
    @include d35-m28;
    color: var(--theme-color);
    text-transform: uppercase;
  }

  h3 {
    font-family: $font-futuraLT-bold;
    @include d30-m24;
  }

  h4 {
    font-family: $font-NimbusSans-Bold;
    @include d25-m20;
  }

  h5 {
    font-family: $font-NimbusSans-Bold;
    @include d18-m16;
  }

  .body-1, p {
    @include d20-m16;
    font-family: $font-NimbusSans;
    color: var(--theme-txt-second-color, $description-color);

  }

  .body-2 {
    @include d20-m16;
    font-family: $font-NimbusSans;
    color: var(--theme-txt-second-color, $description-color);
  }

  .body-3 {
    @include d18-m14;
    font-family: $font-NimbusSans;
    color: var(--theme-txt-second-color, $description-color);
  }

  p {
    margin-bottom: 0;
  }

  table {
    width: 100%;

    td {
      //padding: 0 $spacing-24;
      width: 50%;
      vertical-align: top;
    }

    @media #{$media-not-desktop} {
      tr {
        display: flex;
        flex-wrap: wrap;
      }

      td {
        width: 100%;
      }

    }

  }

  ul, ol {
    padding-bottom: $spacing-16;
  }

  ul {
    list-style: none;
    width: 100%;
    padding-left: $spacing-12;

    li {
      padding-left: $spacing-16;
      position: relative;

      &:before {
        width: 6px;
        height: 6px;
        position: absolute;
        content: '';
        background: $color-white;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style: auto;
    padding-left: 20px;

    li {
      &::marker {
        padding-left: 20px;
      }
    }
  }

  ul li, ol li {
    font-family: $font-NimbusSans;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--theme-txt-second-color);
    @include d18-m16;
  }

}
