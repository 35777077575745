.item-card {
  a{
    text-decoration: none;
  }
  a[href="javascript:void(0);"]{
    cursor: inherit;
  }
  img[class*=fit-] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .fit-cover {
    object-fit: cover;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .pa1 {
    display: block;
    position: relative;
    overflow: hidden;
  }

  &.bg-position-top-center {
    .bg-img-large{
      background-image: var(--theme-top-decor-image-large);
    }
    & .item-card__bgImg {
      z-index: 2;
      top: 0;
      left: 0;

      .pa1 {
        padding-top: 23.157%;
        background-color: transparent !important;
        background-image: var(--theme-top-decor-image);
      }
    }
  }
  & .item-card__content-bg {
    & img {
      transition: 0.3s;
    }
    .item-card__img{
      z-index: 1;

    }
    .bgcolor-img{
      &:hover img {
        transform: scale(1.1);
      }
    }
    .item-card__bgImg{
      .bg-img-large{
          display: none;
      }
    }
  }
  &.width-x2 {
    width: 100%;
    max-width: 655px;

    & .item-card__content-bg {
      .item-card__bgImg{
        .pa1{display: none;}
        .bg-img-large{
          display: block;
        }
      }
    }
  }
}

.item-card__content-bg {
  & .item-card__content {
    max-width: calc(100% - #{$spacing-24} * 2);
    color: $color-black;
    padding: $spacing-16;
    left: $spacing-24;
    z-index: 2;
    background-color: var(--theme-txt-bg-color);
    //line-break: anywhere;
    //word-break: break-all;
    @media #{$media-screen-tablet} {
      left: $spacing-12;
      padding: $spacing-12;
      max-width: calc(100% - #{$spacing-12} * 2);
    }
    & h3,.title {
      text-transform: uppercase;
      font-family: $font-futuraLT-bold;
    }
    & h5 {
      text-transform: uppercase;
      font-size: $fs-20;
      font-family:$font-NimbusSans;
      @media #{$media-screen-tablet} {
        font-size: $fs-18;
      }
      @media #{$media-screen-mobile} {
        text-transform: unset;
      }
    }
    & p ,.desc{
      font-family: $font-NimbusSans;
      @media #{$media-screen-mobile} {
        text-transform: unset;
      }
    }
  }

  & .item-card__bgImg {
    width: 100%;
    height: 100%;

    & .pa1 {
      background-repeat: no-repeat;
      background-size: cover;
      background-color: var(--theme-color);
      background-image: var(--theme-bg-pattern-image);
    }
  }

}

.item-card:not(.bg-position-top-center) {
  .item-card__bgImg .pa1 {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.text-position-bottom-right-mbr-24 .item-card__content-bg {
  margin-bottom: $spacing-24;

  & .item-card__content {
    bottom: -$spacing-24;
    left: $spacing-24;
    @media #{$media-screen-tablet} {
      left: $spacing-12;
    }
  }
}

.text-position-top-right-mtr-24 .item-card__content-bg {
  margin-top: $spacing-24;

  & .item-card__content {
    top: -$spacing-24;
    left: $spacing-24;
    @media #{$media-screen-tablet} {
      left: $spacing-12;
    }
  }
}

.text-position-bottom-right-mb-38 .item-card__content-bg {
  margin-bottom: 38px;

  & .item-card__content {
    bottom: -38px;
    left: $spacing-24;
  }
}

.bg-position-bottom-right-mbr-24 .item-card__content-bg {
  margin-right: $spacing-24;
  margin-bottom: $spacing-24;

  & .item-card__bgImg {
    top: $spacing-24;
    right: -$spacing-24;
  }
}

.text-position-bottom-right-mtr-24 .item-card__content-bg {
  margin: $spacing-24 $spacing-24 $spacing-24 0;
}

.text-position-bottom-left-mb-32 .item-card__content-bg {
  & .item-card__content {
    bottom: $spacing-gutter;;
    left: -$spacing-24;
  }
}
.text-position-bottom-right-mb-32 .item-card__content-bg {
  & .item-card__content {
    bottom: $spacing-gutter;;
    right: -$spacing-24;
    left: inherit;
  }
}
.text-position-bottom-left-mbr-56 .item-card__content-bg {
  & .item-card__content {
    bottom: $spacing-56 ;
    left: -$spacing-56;
  }
}
.text-position-bottom-right-mbr-56 .item-card__content-bg {
  & .item-card__content {
    bottom: $spacing-56;
    right: -$spacing-56;
    left: inherit;
  }
}
.text-position-bottom-right-mbr-24-22{
  & .item-card__content {
    bottom: $spacing-22;
  }
}
.bg-position-top-right-mtr-24 .item-card__content-bg {
  margin-right: $spacing-24;
  margin-top: $spacing-24;

  & .item-card__content {
    bottom: -$spacing-24;
  }

  & .item-card__bgImg {
    top: -$spacing-24;
    right: -$spacing-24;
  }
}

.bg-position-bottom-right-mbr-40 .item-card__content-bg {
  margin-right: $spacing-24;
  margin-top: $spacing-40;

  & .item-card__bgImg {
    top: $spacing-40;
    right: -$spacing-24;
  }
}

.bg-position-bottom-left-mbr-40 .item-card__content-bg {
  margin-left: $spacing-24;
  margin-top: $spacing-40;

  & .item-card__bgImg {
    top: $spacing-40;
    left: -$spacing-24;
  }
}


.bg-position-bottom-right-mbr-56 .item-card__content-bg {
  margin-right: $spacing-56;
  margin-top: $spacing-56;

  & .item-card__bgImg {
    top: $spacing-56;
    right: -$spacing-56;
  }
}
.bg-position-bottom-left-mbr-56 .item-card__content-bg {
  margin-left: $spacing-56;
  margin-top: $spacing-56;

  & .item-card__bgImg {
    top: $spacing-56;
    left: -$spacing-56;
  }
}

@media #{$media-screen-mobile} {
  .card-mb-left-right{
    .item-card {
      margin-bottom: $spacing-24;
      .item-card__container {
        padding: $spacing-16 $spacing-16 0 0 ;
      }
      .item-card__content-bg{
        .bgcolor-img {
          position: relative;
          .item-card__bgImg {
            top: -$spacing-16;
            left: $spacing-16;
            right: inherit;
            bottom: inherit;
          }
        }
        .item-card__content {
          width: calc(100% - 16px*2);
          margin-top: -$spacing-55;
          position: relative !important;
          top: 0;
          left: 0;
          margin-left: $spacing-16;
        }
      }
      &:nth-child(even) {
        .item-card__container {
          padding: $spacing-16 0 0 $spacing-16;
          .item-card__content-bg{
            .item-card__bgImg {
              left: -$spacing-16;
            }
          }

        }
      }
    }
  }
}


// activities
.activities {
  .list-activities {
    display: flex;
    margin: 0;
    overflow: unset;
    position: inherit;
    z-index: -1;
    position: relative;

    & .item-card {
      padding: 0 calc(#{$spacing-16} / 2);
      flex: 0 0 auto;
      width: 100%;
      max-width: 327px;
      &.width-x2 {
        width: 100%;
        max-width: 655px;

        & .item-card__content-bg {
          & .item-card__img {
            & .pa1 {
              padding-top: 74.5%;
            }
          }
        }
      }
      & .item-card__content-bg {
        & .item-card__img {
          & .pa1 {
            padding-top: 152.905%;
          }
        }
      }
    }

    @include swiper-navigation;

  }
  @media #{$media-md} {
    .list-activities {
      & .item-card {
        padding: 0;
        margin-right: $spacing-16;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  @media #{$media-screen-mobile} {
    .list-activities {
      flex-wrap: wrap;
      margin: 0 calc(-#{$spacing-16} / 2);

      & .item-card {
        margin-top: 0;
        width: 50%;
        margin-bottom: $spacing-16;
        &:nth-child(n+6) {
          display: none;
        }

        & .item-card__content {
          left: calc(#{$spacing-16} / 2);
          max-width: calc(100% - #{$spacing-16});
          padding: calc(#{$spacing-16} / 4) calc(#{$spacing-16} / 2);

          h3 {
            font-size: $fs-18;
          }
        }


        &.width-x2 {
          width: 100%;

          & .item-card__content {
            left: $spacing-16;
            max-width: calc(100% - #{$spacing-16} * 2);
            padding: calc(#{$spacing-16} / 2) $spacing-16;

            h3 {
              font-size: 28px;
            }
          }
        }


      }
    }
  }
}


// dine-ws
.dine-ws {
  .list-card {
    width: 65%;
    .item-card {
      width: 100%;
      max-width: 500px;
      margin-right: $spacing-24;
      & .item-card__content-bg {
        & .item-card__img {
          & .pa1 {
            padding-top: 80%;
          }
        }
        & .item-card__bgImg {
          & .pa1 {
            background-repeat: repeat;
            background-size: 35px 35px;
          }
        }
      }
      &:last-child{margin-right: 0;}
    }
  }

  @media #{$media-screen-mobile} {
    .list-card {
      width: 100%;

      .lst-dine-ws-box {
        display: flex;
        width: 100%;
      }

      .item-card {
        padding: 0;
        width: 80vw;
        margin-right: $spacing-16;
        .item-card__content-bg {
          margin: $spacing-16 $spacing-16 $spacing-16 0;

          .item-card__content {
            left: $spacing-16;
            padding: calc(#{$spacing-16} / 2) $spacing-16;
          }

          .item-card__bgImg {
            top: -$spacing-16;
            right: -$spacing-16;
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}

// country-homepage
.country-homepage-dws{
  .list-card {
    .lst-dine-ws-box{
      justify-content: end;
      @media #{$media-screen-mobile} {
        justify-content: center;
      }
    }
    .item-card {
      max-width: calc(500px + #{$spacing-24}*2);
      width: 95%;
      padding: 0;
    }
  }
}

.country-homepage-venues{
  .dine-ws{
    display: block;
    .lst-dine-ws-box{
      justify-content: center;
    }
    .card__title {
      width: 100%;
      min-width: 100%;
      text-align: center;
      margin-bottom: $spacing-gutter;
    }
    .item-card {
      max-width: calc(1136px + #{$spacing-24});
      width: 100%;
      padding: 0;
      .item-card__content-bg .item-card__img .pa1 {
        padding-top: 44.015%;
        @media #{$media-screen-mobile} {
          padding-top: 73%;
        }
      }
    }
  }
}
// end country-homepage



// destinations
.destinations {
  .list-destinations {
    cursor: none;
    & .item-card {
      a {
        cursor: none;
      }
      & .item-card__content-bg {
        & .item-card__img {
          z-index: 1;
        }
        & .item-card__img .pa1, & .item-card__bgImg .pa1 {
          padding-top: 80%;
          background-repeat: repeat;
          background-size: 35px 35px;
        }
      }
    }
  }



  .list-destinations:not(.not-coarse) {
    .item-card {
      width: 80vw !important;
      margin-right: $spacing-16;
      margin-top: 0;
      transform: none !important;

      & .item-card__content-bg {
        margin: $spacing-16;
        margin-top: $spacing-48;
        margin-left: 0;

        & .item-card__bgImg {
          top: $spacing-16;
          right: -$spacing-16;
          width: 100%;
          height: 100%;
        }

        & .item-card__content {
          max-width: calc(100% - #{$spacing-16} * 2);
          padding: calc(#{$spacing-16} / 2) $spacing-16;
          top: -$spacing-48;
          left: $spacing-16;

        }
      }
      &:last-child{
        margin-right: 0;
      }
    }

  }
  @media #{$media-md} {
    .list-destinations:not(.not-coarse){
      .item-card {
        width: 60vw!important;
      }
    }
  }

  @media #{$media-xl} {
    .list-destinations:not(.not-coarse){
      .item-card {
        width: 40%!important;
      }
    }
    .list-destinations.not-coarse {
      display: flex;
      flex-wrap: wrap;
      padding-top: 40px;
      overflow: hidden;

      & .item-card {
        margin-left: $spacing-30;
        margin-right: $spacing-30;
        margin-bottom: $spacing-60;
        height: max-content;
        &__img .pa1,&__bgImg .pa1 {
          padding-top: 80%;
          width: 100%;
          height: 100%;
        }
        &--height.item-card {
          .item-card__img .pa1 {
            padding-top: 125%;
          }
        }

        &:nth-child(3n + 1) {
          margin-left: calc(-156px - 0.1px);
        }

        &:nth-child(3n) {
          margin-right:  calc(-156px - 0.1px);
        }
        &:nth-child(3n-1) {
          .item-card__container{
            transform: translateY(#{-$spacing-40});
          }
        }
        @for $i from 1 through 3 {
          &:nth-last-child(#{$i}) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// featured-offers
@mixin list-featured {
  .list-featured-offers {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(#{-$spacing-16} / 2);
    position: relative;

    & .item-card {
      padding: 0 calc(#{$spacing-16} / 2);;
      flex: 0 0 auto;
      width: calc(100% / 3);
      & .item-card__content-bg {
        & .item-card__img {
          & .pa1 {
            padding-top: 120.195%;
          }
        }
      }
    }
    @media #{$media-screen-mobile} {
        margin: 0;
        & > .item-card {
          width: 100%;
          margin-bottom: $spacing-16;
          padding: 0;
          margin-top: 0 !important;

          & .item-card__content {
            max-width: calc(100% - #{$spacing-16} * 2);
            left: $spacing-16;
            padding: calc(#{$spacing-16} / 2) $spacing-16;
          }
        }
    }
  }
}

.featured-offers {
  @include list-featured;
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 40px;
    color: var(--theme-color);
    z-index: 2;

    .icon {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 40%;
        height: 40%;
        margin: auto;
        background: white;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
      }
    }
  }

  .arrow-left {
      left: 32px;
  }

  .arrow-right {
    right: 32px;
  }
}


// list-related-offers
@mixin list-related-offers{
  .list-related-offers {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-#{$spacing-16} / 2);
    .item-card {
      padding: 0 calc(#{$spacing-16} / 2);
      flex: 0 0 auto;
      width: calc(100% / 3);

      .title{
        @extend .d25-m16;
        margin-bottom: $spacing-8;
      }
      .desc{
        @extend .d18-m14;
      }
      .item-card__content-bg {
        .item-card__bgImg {
          top: 0;
          left: 0;
          height: initial;
          z-index: 2;
          & .pa1{
            padding-top: calc(100% * 90/400);
            background-color: transparent;
          }
        }
        .item-card__img {
          & .pa1 {
            padding-top: 137%;
          }
        }
      }
      @media #{$media-not-desktop} {
          width: 50%;
          margin-bottom: $spacing-16;
          margin-top: 0;
      }
      @media #{$media-screen-mobile} {
          width: 100%;
          padding: 0 calc(#{$spacing-16} / 2);
          .item-card__content-bg{
            margin-bottom: 0 !important;
            .item-card__content {
              bottom: $spacing-16 ;
              padding: $spacing-16;
              left: $spacing-16;
              max-width: calc(100% - #{$spacing-16}*2);
              .title{
                margin-bottom: calc(#{$spacing-8} / 2);
              }
            }
          }
      }
    }
  }
}

// activation-key-features
@mixin  title-card-color-theme {
  .list-related-offers {
    .item-card{
      a[href="javascript:void(0)"]{
        cursor: default;
      }
      .item-card__content{
        .title,h3{
          @extend .d30-m24;
          color: var(--theme-color);
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
      &__content-bg {
        .bgcolor-img:hover img {
          transform: none;
        }
      }
      @media #{$media-md} {
        width: calc(100% / 3);
      }
      @media #{$media-screen-mobile} {
        .item-card__content-bg{
          .item-card__content {
            h3,.title{
              margin-bottom: $spacing-8;
            }
          }
        }
      }
    }
  }
}

.venues-country,
.venues,
.dine{
  .item-card .item-card__content-bg .item-card__bgImg .pa1{
    background-repeat: repeat;
    background-size: 35px 35px;
  }
}
