*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-family: $font-futuraLT;
  color: $color-text-base;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba($black, 0);
}

body {
  margin: 0; // 1
  line-height: $line-height-base;
  color: inherit;
  min-height: 100%;
  background-image: url('#{$image-path}bg-main.png');
  font-family: $font-futuraLT;
  font-size: $font-base-2;
  text-align: left; // 3
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;

  &::before,
  &::after {
    content: none;
  }
}

button,
input {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

input,
button,
select,
optgroup,
textarea {
  margin: 0; // Remove the margin in Firefox and Safari
  font-family: inherit;
  @include font-size(inherit);
  line-height: inherit;
}

button {
  &:focus {
    outline: none;
  }
}

b,
strong {
  font-weight: bold;
}

input {
  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

a {
  color: $link-color;
  // text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.

  &:focus {
    outline: none;
  }
}

@keyframes scale {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: $headings-line-height;
  font-weight: 500;
}

h1 {
  font-size: 64px;
  line-height: 1.2;
  letter-spacing: -1.6px;

  @media #{$media-screen-mobile} {
    font-size: 32px;
    line-height: 1.21;
    letter-spacing: -1px;
  }
}

h2 {
  font-size: 48px;

  @media #{$media-screen-mobile} {
    font-size: 28px;
  }
}

h3 {
  font-size: 34px;

  @media #{$media-screen-mobile} {
    font-size: 24px;
  }
}

h4 {
  font-size: 24px;

  @media #{$media-screen-mobile} {
    font-size: 20px;
  }
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

/* for content */
p { margin-bottom: 1rem; }
