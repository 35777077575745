$spacing-1280:1280px;
$spacing-960:960px;
$spacing-220:220px;
$spacing-180:180px;
$spacing-155:155px;
$spacing-140:140px;
$spacing-135:135px;
$spacing-120:120px;
$spacing-108:108px;
$spacing-104:104px;
$spacing-100:100px;
$spacing-84:84px;
$spacing-80:80px;
$spacing-78:78px;
$spacing-70:70px;
$spacing-63:63px;
$spacing-60:60px;
$spacing-56:56px;
$spacing-55:55px;
$spacing-53:53px;
$spacing-48:48px;
$spacing-45:45px;
$spacing-40:40px;
$spacing-gutter:32px;
$spacing-30:30px;
$spacing-24:24px;
$spacing-22:22px;
$spacing-16:16px;
$spacing-14:14px;
$spacing-12:12px;
$spacing-10:10px;
$spacing-9:9px;
$spacing-8:8px;

$spacing-80pt:80%;
