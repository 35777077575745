.destinations {
  .heading-section {
    text-align: center;
  }
  &__cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    pointer-events: none;
    transition: transform .15s ease-in-out;
    transform: scale(1);
    z-index: 1;

    &.active {
      transform: scale(2);
      mix-blend-mode: difference;
    }

    @media #{$media-container-max-1024} {
      display: none;
    }
  }
  .js-destinations {
    .destinations__cursor {
      display: none;
    }
  }
  .js-destinations:hover {
    .destinations__cursor.not-coarse {
      @media #{$media-xl} {
        display: block;
      }
    }
  }
}
