.list-item, .list-item-2 {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 100px;
  background-color: #f5f5e7;
  padding: 50px 0;

  .item {
    margin-bottom: 20px;

    &__image {
      img {
        max-width: 100%;
      }
    }

    &__title {
      color: #276be3;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 700;
      margin-bottom: 10px;
    }

    &__desc {
      color: #037d9d;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
