.video-media {
  max-width: 854px;
  margin: auto;

  &__info {
    text-align: center;
    margin-bottom: 40px;
  }

  &__title {
    @include textStyle-d36-m24;
    color: $color-primary;
    margin-bottom: 14px;
  }

  &__description {
    @include textStyle-p;
  }

  &__box {
    position: relative;
    padding-bottom: 56%;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 10px;
  }

  @media #{$media-screen-mobile} {
    &__info {
      margin-bottom: 30px;
    }

    &__title {
      margin-bottom: 18px;
    }
  }
}
