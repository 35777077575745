.information {
  .sub-heading {
    text-transform: uppercase;
    text-align: center;

    &__title {
      font-family: $font-NimbusSans-Bold;
      color: var(--theme-color);
      font-size: $fs-28;
      padding-bottom: rem(24);
      @media #{$media-md} {
        font-size: $fs-40;
      }
    }

    &__content {
      font-family:$font-NimbusSans;
      display: block;
      padding-bottom: rem(32);
      @media #{$media-md} {
        display: flex;
        padding-bottom: rem(40);
        justify-content: center;
      }
    }

    &__phone {
      padding-right: 0;
      padding-bottom: rem(17);
      @media #{$media-md} {
        padding-bottom: 0;
        padding-right: rem(50);
      }
    }

    &__phone, &__email {
      display: flex;
      text-transform: lowercase;
      color: var(--theme-txt-second-color);
      @extend .d18-m16;

      i {
        padding-right: rem(10);
      }
      i:before {
        transform: #{"/*!rtl:rotateY(180deg)*/"} unset;
        display: inline-block;
      }

      a {
        text-decoration: none;
        color: var(--theme-txt-second-color);
      }
    }
    .cta {
      margin: auto;
      font-family: $font-futuraLT;
      letter-spacing: 1.8px;
    }
    .cta-pink {
      background: var(--theme-color);
      margin-bottom: rem(16);
      @media #{$media-md} {
        margin-right: rem(32);
      }
    }
    .cta-pink-light {
      color: var(--theme-color);
      border-color: var(--theme-color);
    }
  }
}
