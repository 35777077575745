.activities {
  .heading-section {
    text-align: center;
    position: relative;
    z-index: 1;
    h2{
      display: inline-block;
    }
    .span-hot {
      color: var(--theme-txt-second-color);
      font-family: 'StarlitDrive', sans-serif;
      font-size: 110px;
      text-transform: initial;
      line-height: 75px;
      max-width: 500px;
      display: inline-flex;
      text-align: left;
      display: inline-flex;
      /*!rtl:ignore*/
       margin-left: 1.5rem;
    }
    path:last-child {
      fill: $color-white;
    }
  }
  .btn-arrow {
    display: inline-flex;
    position: absolute;
    right: -3rem #{"/*!rtl:unset*/"};
    left: #{"/*!rtl:-4rem*/"} unset;
    top: 1rem;

    & svg {
      transition: 0.3s;
    }

    &.show svg {
      transform: rotate(180deg);
    }

  }

  .dropdown-menu {
    padding: 0;
    max-width: 390px;
    width: 390px;
    background-color: $color-black-272727;
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 31%);
    border-radius: 0;
    margin-top: 2rem !important;
    flex-direction: column;

    & li{
      width: 100%;
    }
    & li a {
      color: var(--theme-txt-second-color);
      padding: $spacing-16;
      font-size: 25px;
      transition: 0.3s;
      word-wrap: break-word;
      white-space: normal;

      &:hover, &.active {
        background-color: var(--theme-color);
      }
    }

  }
  @media #{$media-not-desktop} {
    & .heading-section {
      .span-hot {
        font-size: 94px;
        line-height: 64px;
        padding-right: 1rem;
        display: inline;
      }
    }
  }
  @media #{$media-screen-mobile} {
    & .heading-section {
      & .heading-section__title{
        position: relative;
      }
      .span-hot {
        font-size: 70px;
      }
      .dropdown{
        position: absolute;
        right: 2.5rem;
        bottom: 4rem;
      }
    }
    .btn-arrow {
      transform: translateY(0);
      top: 0;
    }
    .dropdown-menu {
      max-width: 300px;
      right: 0.5rem !important;

      & li a {
        padding: calc(#{$spacing-16} / 2);
        font-size: $spacing-16;
      }
    }

  }
  &__data {
    display: none;
  }

  .swiper-container {
    .swiper-wrapper {

      @media #{$media-screen-mobile} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}