
.item-faq{
    margin-bottom: $spacing-8;
    background: $color-white;
    color: $color-black-272727;
    &-header{
        .btn{
            color: $color-black-272727;
            text-decoration: none;
            border-radius: 0;
            padding: $spacing-16;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1.4;
            font-family: $font-futuraLT-bold;
            color: var(--theme-color);
            @extend .d18-m14;
            &[aria-expanded="true"]{
                .icon-arrow-down{
                    transform: rotate(180deg);
                }
            }
            .icon-arrow-down{
                font-size: $fs-14;
                transition: 0.2s;
                margin-left: 0.5rem;
            }
        }
    }
    &-body{
        @extend .d18-m14;
        padding: calc(#{$spacing-16}/5) $spacing-24 $spacing-24 $spacing-24;
        font-family: $font-NimbusSans;
    }

    .collapse {
        &:not(.show) {
            display: none;
        }
    }
        
    .collapsing {
        height: 0;
        overflow: hidden;
        @include transition($transition-collapse);
        &.collapse-horizontal {
            width: 0;
            height: auto;
            @include transition($transition-collapse-width);
        }
    }

    @media #{$media-screen-mobile} {
        &-header{
            .btn{
                padding: calc($spacing-16/2);
            }
        }
        &-body{
            padding: 0 $spacing-16 $spacing-16 $spacing-16;
        }
    
    }
} 

