@use "sass:math";

$wSideBar: 560px;
$spacing-md: 24px;

.modal-open .header{
  width: calc(100% - 17px);
}
.header {
  width: 100%;
  position: fixed;
  z-index: $z-index-header;
  background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, transparent 100%);

  &__body {
    padding: $spacing-gutter 0;
    color: $color-white;

    @media #{$media-screen-mobile} {
      padding: $spacing-gutter * 0.5 0;
    }
  }

  a {
    text-decoration: none;
  }

  .navbar {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    &-item {
      flex: 0 0 percentage(math.div(4, 12));
      max-width: percentage(math.div(4, 12));
    }

    &__logo {
      display: flex;
      justify-content: center;


      &-box {
        max-width: 130px;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &__title{
      font-family: $font-starlitDrive;
      @include fontsizeMedia(44px,32px);
      line-height: 1;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__left, &__right {
      display: flex;
      align-items: center;
    }

    &__left {
      .dropdown {
        margin-right: $spacing-gutter;
        padding-right: calc($spacing-16 * 1.5);

        .dropdown-toggle {
          background: none;
          outline: none;
          border: none;
          color: var(--theme-txt-second-color);
        }
      }
    }

    &__icon {
      transform: #{"/*!rtl:rotateY(180deg)*/"} unset;
      a {
        color: $color-white;
        font-size: 27px;
      }

      &-user {
        display: none;
      }
    }

    &__right {
      justify-content: flex-end;

      .nav-item {
        margin-left: $spacing-gutter;
      }

      .toggle-sidebar {
        width: 32px;
        height: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;

        span {
          display: block;
          height: 2px;
          background-color: $color-white;

          &:nth-child(1) {
            width: 100%;
          }

          &:nth-child(2) {
            width: 75%;
          }

          &:nth-child(3) {
            width: 50%;
          }
        }
      }
    }

    &__logo {
      text-align: center;

      a {
        @extend .d35-m28;
        color: $color-white;
      }
    }
    @media #{$media-screen-tablet}{
      .nav-item {
        .btn-primary {
          padding: 12px 14px;
        }
      }
    }
    @media #{$media-screen-mobile} {
      padding-left: 0;
      padding-right: 0;

      &__right {
        justify-content: flex-start;
        order: 0;

        .nav-item {
          margin-left: 0;

          &--login {
            display: none;
          }
        }

        .toggle-sidebar {
          align-items: flex-start;
          width: 20px;
          height: 20px;
        }
      }

      &__logo {
        order: 1;
      }

      &__left {
        justify-content: flex-end;
        order: 2;

        .dropdown {
          display: none;
        }
      }

      &__icon {
        display: flex;
        flex-direction: row-reverse;

        & > a {
          margin-left: $spacing-md;
        }

        &-user {
          display: block;
        }
      }
    }
  }

  .dropdown {

    .dropdown-toggle {
      text-align: left;
      position: relative;
      cursor: pointer;

      &:after {
        display: none;
      }

      &:before {
        position: absolute;
        content: '\e901';
        font-family: 'icomoon' !important;
        font-size: 10px;
        right: calc($spacing-16 * -1.5);
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        transition: all 0.5s linear;
      }

      &.show {
        &:before {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }

    .dropdown-menu {
      border-radius: unset;
      padding: 0;
      background-image: url('#{$image-path}bg-main.png');
      margin-top: rem(10);

      .dropdown-item {
        padding: rem(8) rem(16);
        color: var(--theme-txt-second-color);

        &.active {
          background-color: $color-primary;
          color: $color-calendar-normal;
        }

        &:hover {
          background-color: $color-primary;
          color: $color-calendar-normal;
          cursor: pointer;
        }
      }
    }
  }

  .sidebar {
    max-width: $wSideBar;
    width: $wSideBar;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 1s;
    z-index: 999;


    &__content {
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 0 80px;

      &-mobile {
        display: none;
      }
    }

    &__item {
      @extend .d35-m28;
      color: $color-primary;
      margin-bottom: 40px;
      font-family: $font-futuraLT-bold;
      font-weight: bold;
      font-size: $fs-28;
      display: block;
      text-transform: uppercase;
      }

    &__item-dropdown {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-family: $font-futuraLT-bold;
        &.label {
          font-size: 20px;
          font-weight: bold;
        }

        &.value {
          font-size: 16px;
          font-family: $font-FuturaLT-Regular;
        }
      }
    }

    &__close {
      display: none;
      position: absolute;
      top: $spacing-md;
      right: $spacing-md;

      span {
        font-size: $spacing-md;
      }
    }

    @media #{$media-screen-mobile} {
      max-width: 100%;
      width: 100%;
      background-image: url('#{$image-path}bg-main.png');
      background-position: center;
      background-repeat: repeat;
      background-size: contain;

      &__content {
        justify-content: start;
        padding: 80px 40px 0;

        &-mobile {
          display: block;
          width: 100%;
        }
      }

      &__close {
        display: block;
      }

      .dropdown  {

        .dropdown-toggle {
          padding-right: rem(30);
        }

        .dropdown-menu {
          background: $color-white;
          //min-width: 110px;
        }

        .dropdown-item {
          color: $color-black-272727 !important;
        }
      }
    }
  }

  &.show {
    .toggle-sidebar {
      span {

        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          display: block;
          width: 100%;
          position: relative;
          transform: rotate(45deg);

          &:before {
            content: '';
            position: absolute;
            transform: rotate(90deg);
            background: $color-white;
            width: 100%;
            height: 2px;
          }
        }

        &:nth-child(3) {
          display: none;
        }
      }
    }
  }


  .btn.btn-primary {
    background-color: var(--theme-color);
    color:  var(--theme-txt-color);
  }
  & .sidebar__item{
    color: var(--theme-color);
  }
  & .navbar__title{
    color: var(--theme-color);
  }
}

.header--stb-campaign-site{
  .navbar__title{
    color: $color-16abff;
  }
}

.header{
  &.header--stb-campaign-site{
    .navbar{     
      &__right{      
        .toggle-sidebar {
          display: none;
        }
      }
    }
  }
  @media #{$media-screen-mobile}{
    &.header--stb-campaign-site{
      .navbar{
        &__icon-user{  
          display: none;   
        }
        &__right{
          .nav-item--playgame{
            display:none;
          }
        }
      }
    }
  }
}

